import React from 'react';
import Img from 'gatsby-image';
import get from 'lodash/get';
import './Image.scss';

const Image = (props) => {
  const { image, className = '', style = {} } = props;

  // const [loaded, setLoaded] = useState(false);
  // const imgRef = useRef();

  // useEffect(() => {
  //   if (imgRef.current && imgRef.current.complete) {
  //     setLoaded(true);
  //   }
  // }, []);

  let fluid = null;
  const imgixFluid = get(image, 'fluid', null);
  const gatsbyFluid = get(image, ['localFile', 'childImageSharp', 'fluid'], null);

  if (imgixFluid) {
    fluid = imgixFluid;
  } else if (gatsbyFluid) {
    fluid = gatsbyFluid;
  }

  if (!fluid) {
    return (
      <div className="image-wrapper" style={style}>
        <img className={`imgix-image ${className}`} src={image.url} alt={image.alt || ''} />
      </div>
    );
  }

  return (
    <div className="image-wrapper" style={style}>
      <Img
        className={`image ${className}`}
        fluid={fluid}
        alt={image.alt || ''}
        // ref={imgRef}
        // onLoad={() => setLoaded(true)}
      />
    </div>
  );
};

export default Image;
