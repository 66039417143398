import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { MessageSpinner } from '.';
import { WAGEC_MESSAGE, ANIMATION_DELAY, TYPING_DELAY } from '../../dialogue';
import { HtmlContent } from '../common';
import './Dialogue.scss';

const DialogueMessage = (props) => {
  const {
    index,
    type,
    message,
    anchorWindowToBottom,
    setAvatarPosition,
  } = props;
  const messageRef = useRef(null);
  const [showElement, setShowElement] = useState(type !== WAGEC_MESSAGE);
  const [showContent, setShowContent] = useState(type !== WAGEC_MESSAGE);
  const handleAvatarPosition = () => {
    if (messageRef && messageRef.current) {
      const {
        offsetTop,
        offsetHeight,
      } = messageRef.current;
      setAvatarPosition(offsetTop + offsetHeight);
    }
  };
  useEffect(() => {
    if (type === WAGEC_MESSAGE) {
      setTimeout(() => {
        setShowElement(true);
        handleAvatarPosition();
        anchorWindowToBottom();
        setTimeout(() => {
          setShowContent(true);
          anchorWindowToBottom();
          handleAvatarPosition();
        }, TYPING_DELAY);
      }, TYPING_DELAY + (index * ANIMATION_DELAY));
    } else {
      anchorWindowToBottom();
    }
  }, []);
  return (
    <CSSTransition
      in={showElement}
      timeout={ANIMATION_DELAY}
      classNames="animated-in"
      unmountOnExit
    >
      <div className="dialogue-message" ref={messageRef}>
        { showContent ? (
          <HtmlContent content={message} />
        ) : (
          <MessageSpinner />
        )}
      </div>
    </CSSTransition>
  );
};

const DialogueAnswerOptions = (props) => {
  const {
    answers,
    setNextDialogue,
    revertDialogue,
    timeoutDuration,
    canRevert,
    anchorWindowToBottom,
  } = props;
  const [showAnswers, setShowAnswers] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowAnswers(true);
      anchorWindowToBottom();
    }, timeoutDuration);
  });
  return (
    <CSSTransition
      in={showAnswers}
      timeout={timeoutDuration}
      classNames="animated-in"
      unmountOnExit
    >
      <div className="answer-options">
        <span className="label">Choose an option</span>
        <div className="options">
          { canRevert && (
            <button type="button" className="revert-button" onClick={revertDialogue}>
              <svg viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.501 7.458H3.993l5.977-5.44A1.02 1.02 0 008.61.508L.685 7.706a1.02 1.02 0 000 1.51L8.58 16.42a1.02 1.02 0 001.36-1.51L4.027 9.498h13.474a6.12 6.12 0 010 12.212h-4.42a1.02 1.02 0 100 2.04h4.42a8.16 8.16 0 000-16.292z" />
              </svg>
            </button>
          )}
          { answers.map((answer) => {
            const {
              message,
            } = answer;
            return (
              <button
                key={`${message}`}
                className="option-button styled-button styled-button-primary"
                type="button"
                onClick={(() => setNextDialogue(answer))}
              >
                <span>{ message }</span>
              </button>
            );
          })}
        </div>
      </div>
    </CSSTransition>
  );
};

const StartAgainButton = (props) => {
  const {
    resetDialogueHandler,
    timeoutDuration,
    anchorWindowToBottom,
  } = props;
  const [showAnswers, setShowAnswers] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowAnswers(true);
      anchorWindowToBottom();
    }, timeoutDuration);
  });
  return (
    <CSSTransition
      in={showAnswers}
      timeout={timeoutDuration}
      classNames="animated-in"
      unmountOnExit
    >
      <div className="answer-options">
        <span className="label">Did this help?</span>
        <div className="options">
          <button
            className="option-button styled-button styled-button-primary"
            type="button"
            onClick={() => resetDialogueHandler()}
          >
            <span>Start Again</span>
          </button>
        </div>
      </div>
    </CSSTransition>
  );
};

const Dialogue = (props) => {
  const {
    interaction,
    setNextDialogue,
    canRevert,
    isCurrentDialogue,
    revertDialogue,
    anchorWindowToBottom,
    setAvatarPosition,
    resetDialogueHandler,
  } = props;
  const {
    type,
    messages,
    answers,
  } = interaction;
  const dialogueClass = type === WAGEC_MESSAGE ? 'wagec-message' : 'user-response';
  const otherClasses = `${isCurrentDialogue ? ' is-current-dialogue' : ''}`;
  return (
    <div className={`dialogue ${dialogueClass}${otherClasses}`}>
      <div className="message-set">
        { messages && messages.length > 0 && messages.map((message, index) => (
          <DialogueMessage
            key={`${index}+${message}`}
            type={type}
            index={index}
            message={message}
            anchorWindowToBottom={anchorWindowToBottom}
            setAvatarPosition={setAvatarPosition}
          />
        ))}
      </div>
      { isCurrentDialogue && type === WAGEC_MESSAGE && (
        answers && answers.length > 0 ? (
          <DialogueAnswerOptions
            answers={answers}
            setNextDialogue={setNextDialogue}
            revertDialogue={revertDialogue}
            timeoutDuration={messages.length * ANIMATION_DELAY}
            canRevert={canRevert}
            anchorWindowToBottom={anchorWindowToBottom}
          />
        ) : (
          <StartAgainButton
            timeoutDuration={messages.length * ANIMATION_DELAY}
            anchorWindowToBottom={anchorWindowToBottom}
            resetDialogueHandler={resetDialogueHandler}
          />
        )
      )}
    </div>
  );
};

export default Dialogue;
