import React from 'react';
import {
  Buttons,
} from '../common';
import './PostButtons.scss';

const PostButtons = (props) => {
  const {
    data,
  } = props;
  const {
    buttons,
  } = data;
  return (
    <section className="post-buttons">
      {buttons && buttons.length > 0 && (
        <Buttons
          buttons={buttons}
        />
      )}
    </section>
  );
};

export default PostButtons;
