import React from 'react';
import { Wrapper } from '.';
import './SimplePageHero.scss';

const SimplePageHero = (props) => {
  const {
    headline,
    description,
    theme,
  } = props;
  const themeClass = theme ? ` theme-${theme}` : '';
  return (
    <section className={`simple-page-hero${themeClass}`}>
      <Wrapper isBound>
        <div className="text-container">
          <h1 className="headline">{headline}</h1>
          { description && (
            <p className="description">{description}</p>
          )}
        </div>
      </Wrapper>
    </section>
  );
};

export default SimplePageHero;
