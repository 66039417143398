import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react';
import gsap from 'gsap';
import { ReactComponent as CloseIcon } from '../../icons/close-icon.svg';
import { ReactComponent as ArrowRightIcon } from '../../icons/arrow-right-icon.svg';
import { isClient } from '../../../utils/helpers';
import './DialogueTooltip.scss';

const scrollThreshold = 200;

const tooltipStorageKey = 'tooltip-disclaimer-key';
const getTooltipDisclaimerFlag = () => localStorage.getItem(tooltipStorageKey); // eslint-disable-line
const setTooltipDisclaimerFlag = () => localStorage.setItem(tooltipStorageKey, true); // eslint-disable-line

const DialogueTooltip = ({ dialogueOpen, dialogueToggleHandler }) => {
  const [visible, setVisible] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const show = visible && !hasInteracted && !scrolled;
  const ref = useRef();
  const containerRef = useRef();

  const dismissNotice = useCallback(() => {
    setTooltipDisclaimerFlag(false);
    setVisible(false);
  }, []);

  const handleScroll = useCallback(() => {
    const currScroll = window.pageYOffset;
    if (currScroll > scrollThreshold) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }, []);

  useEffect(() => {
    if (isClient) {
      window.addEventListener('scroll', handleScroll, { passive: true });
      return () => {
        window.addEventListener('scroll', handleScroll, { passive: true });
      };
    }
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      // Set tooltip for initially hidden
      gsap.set(ref.current, {
        pointerEvents: 'none',
      });
      gsap.set(containerRef.current, {
        opacity: 0,
        xPercent: -10,
      });
      // Reveal tooltip after 1s
      setTimeout(() => {
        if (!getTooltipDisclaimerFlag()) {
          setVisible(true);
        }
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      gsap.to(containerRef.current, {
        opacity: show ? 1 : 0,
        xPercent: show ? 0 : -10,
        duration: 0.25,
      });
      gsap.to(ref.current, {
        pointerEvents: show ? 'all' : 'none',
      });
    }
  }, [show]);

  useEffect(() => {
    if (dialogueOpen) {
      gsap.set(containerRef.current, {
        opacity: 0,
      });
      setHasInteracted(true);
    }
  }, [dialogueOpen]);

  return (
    <button
      className="dialogue-tooltip"
      ref={ref}
      type="button"
      onClick={() => dialogueToggleHandler()}
      aria-label="A pop-up: Access an interactive questionnaire about domestic violence"
    >
      <div className="container" ref={containerRef} style={{ opacity: 0 }}>
        <button
          type="button"
          onClick={dismissNotice}
          aria-label="Close questionnaire pop-up"
        >
          <CloseIcon className="close-icon" />
        </button>
        <span className="tooltip-text">We're here for you</span>
        <ArrowRightIcon className="arrow-right-icon" />
      </div>
    </button>
  );
};


export default DialogueTooltip;
