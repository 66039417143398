import React from 'react';
import { LatestPosts } from '../blog';

const LatestPostsBlock = (props) => {
  const {
    data,
    siteMetadata,
  } = props;
  const {
    primary,
  } = data;
  const {
    headline,
    subtitle,
  } = primary;
  const {
    blogSlug,
  } = siteMetadata;
  return (
    <section className="latest-posts-block">
      <LatestPosts
        headline={headline}
        subtitle={subtitle}
        blogSlug={blogSlug}
        grayscalePhotos
      />
    </section>
  );
};

export default LatestPostsBlock;
