import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Wrapper, HtmlContent } from '../common';
import { ContactForm } from '../Forms';
import './ContactFormBlock.scss';

const getSlug = (location) => {
  if (!location) return null;
  const {
    pathname = '',
  } = location;
  // Remove all forward slashes to get just the slug
  return pathname ? pathname.replace(/\//g, '') : '';
};

const ContactFormTemplate = (props) => {
  const {
    data,
    settings,
    location,
  } = props;
  const {
    primary,
  } = data;
  const {
    title,
  } = primary;
  const {
    businessName,
    address,
    addressGoogleMapsLink,
    openingTime,
    openingDay,
    closingTime,
    closingDay,
    officeTelephone,
    donationsTelephone,
  } = settings;
  const formAffix = getSlug(location);
  return (
    <section className="contact-form-block">
      <Wrapper isBound>
        <h2 className="form-title">{title.text}</h2>
        <div className="cols">
          <div className="col col-left">
            <div className="office-details">
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 37">
                  <path
                    fill="#E6D7FA"
                    fillRule="nonzero"
                    d="M14.5958.5c6.405 0 12.0463 4.2855 13.7184 10.422.3295 1.2089.496 2.4643.496 3.7299-.2145 6.3424-4.476 11.089-7.901 14.9039-1.9631 2.187-3.9931 4.4484-5.6442 6.978a.7317.7317 0 0 1-.6102.3296.7265.7265 0 0 1-.6088-.3295c-1.4954-2.2904-3.3814-4.3815-5.2056-6.4043-2.2155-2.4562-4.5059-4.996-6.1824-7.9643C1.314 19.7847.5873 17.2645.5 14.6766.5 7.5937 5.7674 1.5626 12.7528.6229A13.9417 13.9417 0 0 1 14.5958.5zm-.0007 1.4547c-.5498 0-1.1048.037-1.6496.1098C6.7813 2.8937 1.9532 8.4222 1.9532 14.652c.0793 2.3274.7426 4.6222 1.9704 6.7984 1.5972 2.8286 3.8338 5.3081 5.9962 7.7062 1.6241 1.8016 3.2977 3.6556 4.732 5.6849 1.5813-2.2577 3.404-4.287 5.1729-6.2574 4.1553-4.628 7.349-8.5942 7.5309-13.9569a12.7612 12.7612 0 0 0-.4459-3.3225c-1.4758-5.418-6.6551-9.35-12.3146-9.35zm.0129 4.7917c4.5387 0 8.2182 3.6794 8.2182 8.2182 0 4.5387-3.6795 8.2182-8.2182 8.2182-4.5388 0-8.2182-3.6795-8.2182-8.2182 0-4.5388 3.6794-8.2182 8.2182-8.2182zm0 1.5c-3.7104 0-6.7182 3.0078-6.7182 6.7182 0 3.7103 3.0078 6.7182 6.7182 6.7182 3.7103 0 6.7182-3.0079 6.7182-6.7182 0-3.7104-3.0079-6.7182-6.7182-6.7182z"
                  />
                </svg>
              </div>
              <div className="text-container">
                <h3 className="details-title">Our Office</h3>
                <p className="business-name">
                  <strong>
                    {businessName.text}
                  </strong>
                </p>
                <a
                  href={addressGoogleMapsLink.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="address"
                >
                  <HtmlContent content={address.html} />
                </a>
                <div className="office-hours">
                  <p className="open-days">
                    <strong>{openingDay.text}</strong>
                    <span> - </span>
                    <strong>{closingDay.text}</strong>
                  </p>
                  <p className="open-hours">
                    {`${openingTime.text} - ${closingTime.text}`}
                  </p>
                </div>
              </div>
            </div>
            <div className="phone-details">
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
                  <path
                    fill="#E6D7FA"
                    fillRule="nonzero"
                    d="M26.364 18.4595L21.74 16.0961a2.2125 2.2125 0 0 0-2.6557.501l-1.156 1.2844-.8927 1.0244c-.3693.183-2.5915 1.0597-6.233-2.569-3.6416-3.6287-2.7617-5.8798-2.569-6.2298l2.3089-2.052a2.2125 2.2125 0 0 0 .488-2.6524L8.667.7785A1.1759 1.1759 0 0 0 7.556.0493H5.7672a1.8452 1.8452 0 0 0-.3661.0452 7.0326 7.0326 0 0 0-.6808.2152l-.0931.032C2.5689 1.1286 1.249 2.7278.6068 3.6783a3.1213 3.1213 0 0 0-.5395 2.0841c.0225.1895.0418.4014.061.6423.2377 2.524.6808 7.222 7.0326 13.5738 6.3518 6.3518 11.0499 6.7821 13.5739 7.0166l.6422.0642c.1066.013.2138.0193.3212.0193a3.2112 3.2112 0 0 0 1.7693-.5588 8.8501 8.8501 0 0 0 3.3333-4.059v-.0225c0-.0257 0-.0514.0257-.0738a6.1013 6.1013 0 0 0 .2023-.6423c.0266-.1137.0406-.23.0417-.3468v-1.795a1.1721 1.1721 0 0 0-.7064-1.1208zM5.6164 1.029a.6904.6904 0 0 1 .1477-.0193h1.792a.212.212 0 0 1 .2183.1285.2569.2569 0 0 0 .0257.0513l2.3634 4.6338a1.246 1.246 0 0 1-.2761 1.5061l-.851.7579-3.5324-7.023.1124-.0353zM22.912 25.7169a2.1997 2.1997 0 0 1-1.4354.3853l-.6423-.0642c-2.402-.228-6.8752-.6422-12.983-6.7436C1.7436 13.1931 1.3165 8.7038 1.0885 6.3018c-.0225-.2376-.0417-.456-.0642-.6422a2.1933 2.1933 0 0 1 .3853-1.4355 8.0538 8.0538 0 0 1 3.192-2.8258l3.6897 7.344-.7707.684a.4913.4913 0 0 0-.0835.0996c-.077.1188-1.824 2.9736 2.6846 7.479 4.5085 4.5053 7.3601 2.7616 7.479 2.6845a.4913.4913 0 0 0 .0995-.0835l.7193-.8092 7.3376 3.6769a8.0056 8.0056 0 0 1-2.8451 3.2433zm3.2112-4.348a.607.607 0 0 1-.016.1252.533.533 0 0 1-.0193.0675l-7.0069-3.51.7161-.8123a1.2395 1.2395 0 0 1 1.506-.2762l4.6467 2.3699a.2569.2569 0 0 0 .0514.0257.212.212 0 0 1 .1284.2183l-.0064 1.7919z"
                  />
                </svg>
              </div>
              <div className="text-container">
                <h3 className="details-title">Call Us</h3>
                {officeTelephone && officeTelephone.text && (
                  <div className="contact-number">
                    <p>Intake</p>
                    <a href={`tel:${officeTelephone.text}`}>
                      <span className="srOnly">Call: </span>
                      {officeTelephone.text.split('').map((digit, index) => <span key={`${digit}-${index}`}>{digit}</span>)}
                    </a>
                  </div>
                )}
                {donationsTelephone && donationsTelephone.text && (
                  <div className="contact-number">
                    <p>Donations</p>
                    <a href={`tel:${donationsTelephone.text}`}>
                      <span className="srOnly">Call: </span>
                      {donationsTelephone.text.split('').map((digit, index) => <span key={`${digit}-${index}`}>{digit}</span>)}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col col-right">
            <ContactForm
              formName={`contact-form${formAffix ? `-${formAffix}` : ''}`}
            />
          </div>
        </div>
      </Wrapper>
    </section>
  );
};

class ContactFormBlock extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query ContactFormQuery {
            settings: prismicSettings {
              data {
                address {
                  html
                }
                addressGoogleMapsLink: address_google_maps_link {
                  link_type
                  target
                  url
                }
                openingTime: opening_time {
                  text
                }
                openingDay: opening_day {
                  text
                }
                closingTime: closing_time {
                  text
                }
                closingDay: closing_day {
                  text
                }
                businessName: business_name {
                  text
                }
                officeTelephone: office_telephone {
                  text
                }
                donationsTelephone: donations_telephone {
                  text
                }
              }
            }
          }
        `}
        render={data => <ContactFormTemplate {...this.props} settings={data.settings.data} />}
      />
    );
  }
}

export default ContactFormBlock;
