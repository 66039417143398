import React from 'react';
import {
  Wrapper,
  HtmlContent,
  VideoBlock,
} from '../common';
import { convertColorToTheme } from '../../utils/helpers';
import './VideoTextBlock.scss';

const VideoTextBlock = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
  } = data;
  const {
    theme,
    headline,
    subtitle,
    description,
    videoProvider,
    videoUrl,
  } = primary;
  const themeClass = convertColorToTheme(theme.toLowerCase());
  return (
    <section className={`video-text-block theme-${themeClass}`}>
      <Wrapper isBound>
        <div className="container">
          <div className="col text-col">
            <div className="text-container">
              <span className="subtitle">{subtitle.text}</span>
              <h2 className="headline">{headline.text}</h2>
              <HtmlContent className="description" content={description.html} />
            </div>
          </div>
          <div className="col video-col">
            <VideoBlock
              videoProvider={videoProvider.toLowerCase()}
              videoUrl={videoUrl.url}
            />
          </div>
        </div>
      </Wrapper>
    </section>
  );
};

export default VideoTextBlock;
