import React from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import { Image, Wrapper } from '../common';
import { convertColorToTheme } from '../../utils/helpers';
import './ImageBlock.scss';

const ImageBlock = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
  } = data;
  const {
    theme,
    image,
  } = primary;
  const themeClass = `theme-${convertColorToTheme(theme.toLowerCase())}`;
  const aspectRatio = get(image, 'fluid.aspectRatio', 1);
  return (
    <section className={cn('image-block', themeClass)}>
      <Wrapper isBound>
        <div
          className="image-container"
          style={{ paddingBottom: `${100 / aspectRatio}%` }}
        >
          <Image image={image} />
        </div>
      </Wrapper>
    </section>
  );
};

export default ImageBlock;
