import React, {
  useRef,
  useState,
  useEffect,
} from 'react';
import FocusTrap from 'focus-trap-react';
import cn from 'classnames';
import {
  Navigation,
  Toolbar,
  MobileNavigationButton,
} from '.';
import { Wrapper, Logo } from '../common';
import { isClient } from '../../utils/helpers';
import useBodyScrollLock from '../../hooks/useBodyScrollLock';
import useComposeRefs from '../../helpers/composeRefs';
import './Header.scss';

const bodyScrollLockOptions = {
  allowTouchMove: (el) => {
    while (el && el !== document.body) {
      if (el.getAttribute('data-body-scroll-lock-ignore') !== null) {
        return true;
      }
      el = el.parentElement; // eslint-disable-line
    }
  },
};

const Header = (props) => {
  const {
    donateLink,
    navOpen,
    dialogueOpen,
    toggleNavHandler,
    location,
    navigation,
    quickExitHandler,
    closeNavHander,
    activeNavParent,
    setActiveNavParent,
  } = props;

  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [viewportTopPos, setViewportTopPos] = useState(isClient ? window.scrollY : 0);

  const bodyScrollRef = useBodyScrollLock(activeNavParent || navOpen, bodyScrollLockOptions);

  useEffect(() => {
    const handleScroll = () => {
      if (isClient) setViewportTopPos(window.scrollY);
    };
    const handleResize = () => {
      setHeaderHeight(headerRef.current.clientHeight);
    };
    if (isClient) {
      setHeaderHeight(headerRef.current.clientHeight);
      if (isClient) {
        setViewportTopPos(window.scrollY);
        window.addEventListener('resize', handleResize, false);
        window.addEventListener('scroll', handleScroll, false);
      }
    }
    return () => {
      if (isClient) {
        window.removeEventListener('resize', handleResize, false);
        window.removeEventListener('scroll', handleScroll, false);
      }
    };
  });

  const headroomTop = headerHeight === 0 || viewportTopPos < (headerHeight / 2);
  const headerClasses = cn(
    'header',
    { dialogueOpen },
    headroomTop ? 'headroom--top' : 'headroom--not-top',
    navOpen && 'nav-open',
    activeNavParent && 'sub-nav-open',
  );

  const ref = useComposeRefs(bodyScrollRef, headerRef);

  return (
    <header
      className={headerClasses}
      ref={ref}
    >
      <FocusTrap active={navOpen || activeNavParent !== null}>
        <Wrapper>
          <Logo
            clickable
            closeNavHander={closeNavHander}
            collapsed={!headroomTop}
          />
          <Navigation
            navigation={navigation}
            location={location}
            navOpen={navOpen}
            scrolled={!headroomTop}
            closeNavHander={closeNavHander}
            activeParent={activeNavParent}
            showChildrenHandler={setActiveNavParent}
          />
          <Toolbar
            donateLink={donateLink}
            navOpen={navOpen}
            quickExitHandler={quickExitHandler}
          />
          <MobileNavigationButton
            navOpen={navOpen}
            toggleNavHandler={toggleNavHandler}
          />
        </Wrapper>
      </FocusTrap>
    </header>
  );
};

export default Header;
