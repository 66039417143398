import React from 'react';
import './Flag.scss';

export const FLAG_TYPE_LGBT = 'FLAG_TYPE_LGBT';
export const FLAG_TYPE_TRANS = 'FLAG_TYPE_TRANS';
export const FLAG_TYPE_ABORIGINAL = 'FLAG_TYPE_ABORIGINAL';
export const FLAG_TYPE_TORRES_STRAIT = 'FLAG_TYPE_TORRES_STRAIT';

const Flag = ({ flagType }) => {
  switch (flagType) {
    case (FLAG_TYPE_LGBT):
      return (
        <div className="flag">
          <div className="container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 777 480" preserveAspectRatio="none">
              <path fill="#750787" d="M0 0h777v480H0z" />
              <path fill="#004dff" d="M0 0h777v400H0z" />
              <path fill="#008026" d="M0 0h777v320H0z" />
              <path fill="#ffed00" d="M0 0h777v240H0z" />
              <path fill="#ff8c00" d="M0 0h777v160H0z" />
              <path fill="#e40303" d="M0 0h777v80H0z" />
            </svg>
          </div>
        </div>
      );
    case (FLAG_TYPE_TRANS):
      return (
        <div className="flag">
          <div className="container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 480" preserveAspectRatio="none">
              <path fill="#5BCEFA" d="M0 0h800v480H0z" />
              <path fill="#F5A9B8" d="M0 96h800v288H0z" />
              <path fill="#FFF" d="M0 192h800v96H0z" />
            </svg>
          </div>
        </div>
      );
    case (FLAG_TYPE_ABORIGINAL):
      return (
        <div className="flag">
          <div className="container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 6" preserveAspectRatio="none">
              <path d="M0 0h10v6H0z" />
              <path fill="#c00" d="M0 3h10v3H0z" />
              <circle cx="5" cy="3" r="1.5" fill="#ff0" />
            </svg>
          </div>
        </div>
      );
    case (FLAG_TYPE_TORRES_STRAIT):
      return (
        <div className="flag">
          <div className="container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 400" preserveAspectRatio="none">
              <path fill="#096" d="M0 330h600v70H0zM0 0h600v70H0z" />
              <path d="M0 70h600v20H0zM0 310h600v20H0z" />
              <path fill="#009" d="M0 90h600v220H0z" />
              <path fill="#fff" d="M290 95c-100 30-95 105-63 174l-38 36 69-25v-92c22-33 62-33 84 0v92l69 25-38-36c32-69 37-144-63-174l-10 43-10-43z" />
              <path fill="#fff" d="M338 197.0507l-23.3385 17.9661L323.516 244l-23.4969-17.805-23.4737 17.7705 8.8166-28.9702L262 196.995l28.9459-.0995L299.981 168l9.0729 28.9087 28.946.142z" />
            </svg>
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default Flag;
