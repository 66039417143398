import React, { useState } from 'react';
import { Link } from 'gatsby';
import './Categories.scss';

const Categories = (props) => {
  const {
    categories,
    siteMetadata,
    location,
  } = props;
  const {
    blogSlug,
    categorySlug,
  } = siteMetadata;
  const [navOpen, setNavOpen] = useState(false);
  const { pathname: currentPath } = location;
  const isBlogHome = currentPath.includes(blogSlug) && !currentPath.includes(categorySlug);
  const currentCategory = !isBlogHome && categories.filter(category => currentPath.includes(`${categorySlug}/${category.uid}`))[0];
  const currentCategoryLabel = currentCategory ? currentCategory.data.categoryName.text : 'All';
  return (
    <div className={`categories${navOpen ? ' nav-open' : ''}`}>
      <button
        type="button"
        className="mobile-category-selector"
        onClick={() => setNavOpen(!navOpen)}
        aria-label="Reveal other categories"
      >
        <span>{currentCategoryLabel}</span>
        <div className="icon">
          <svg viewBox="0 0 12 8" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.6.154l1.697 1.697L5.65 7.5 0 1.851 1.697.154 5.65 4.106z" fillRule="nonzero" />
          </svg>
        </div>
      </button>
      <ul className={`categories-list${navOpen ? ' nav-open' : ''}`}>
        <li className="category">
          <Link
            className={`container ${isBlogHome ? 'active' : ''}`}
            to={`/${blogSlug}/`}
            aria-label="See all blog posts"
          >
            <span>All</span>
          </Link>
        </li>
        {(categories && categories.length > 0) && (
          categories.map((category) => {
            const { uid, data } = category;
            const { categoryName } = data;
            const categoryPath = `/${blogSlug}/${categorySlug}/${uid}/`;
            const isActive = currentPath.includes(categoryPath);
            return (
              <li className="category" key={uid}>
                <Link
                  className={`container ${isActive ? 'active' : ''}`}
                  to={`${categoryPath}`}
                  aria-label={`Read more about ${categoryName.text}`}
                >
                  <span>{categoryName.text}</span>
                </Link>
              </li>
            );
          })
        )}
      </ul>
    </div>
  );
};

export default Categories;
