import React from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { Link } from 'gatsby';
import { Wrapper } from '../../common';
import { toKebabCase } from '../../../utils/helpers';
import './NavItem.scss';

const ParentIcon = () => (
  <span className="icon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8">
      <g fillRule="nonzero">
        <path className="vertical-line" d="M3.2041.75a.75.75 0 0 1 1.5 0v6a.75.75 0 0 1-1.5 0v-6z" />
        <path className="horizontal-line" d="M6.9541 3a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1 0-1.5h6z" />
      </g>
    </svg>
  </span>
);

const NavLink = (props) => {
  const {
    className,
    label,
    link,
    id,
    hasChildren,
    description,
    showChildrenHandler,
    closeNavHander,
  } = props;
  if (hasChildren) {
    return (
      <button
        id={id}
        className={cn(className || null, 'nav-item-container')}
        type="button"
        onClick={event => showChildrenHandler(event, id)}
        aria-label={`Reveal pages for ${label.text}`}
      >
        { hasChildren && <ParentIcon />}
        <p className="nav-label">{label.text}</p>
      </button>
    );
  }
  if (!link || link.url === '') {
    return (
      <p
        className={cn(className || null, 'nav-item-container')}
        id={id}
      >
        { hasChildren && <ParentIcon />}
        <span className="nav-label">{label.text}</span>
        {description && <p className="description">{description.text}</p>}
      </p>
    );
  }
  if (!link.slug && link.url) {
    return (
      <a
        className={cn(className || null, 'nav-item-container')}
        id={id}
        href={link.url}
        aria-label={`Navigate to ${label.text} page`}
        onClick={event => closeNavHander(event)}
      >
        {hasChildren && <ParentIcon />}
        <p className="nav-label">{label.text}</p>
        {description && <p className="description">{description.text}</p>}
      </a>
    );
  }
  return (
    <Link
      className={cn(className || null, 'nav-item-container')}
      id={id}
      to={link.url}
      aria-label={`Navigate to ${label.text} page`}
      onClick={event => closeNavHander(event)}
    >
      { hasChildren && <ParentIcon />}
      <p className="nav-label">{label.text}</p>
      {description && <p className="description">{description.text}</p>}
    </Link>
  );
};

const SubNav = ({ navChildren, closeNavHander }) => (
  <div className="sub-nav-items">
    {navChildren.map((navItem) => {
      const {
        id,
        primary,
        items: subSubNav,
      } = navItem;
      const {
        label: subNavLinkLabel,
        link: subNavLink,
        description,
      } = primary;

      const hasSubSubNav = !isEmpty(get(subSubNav, '[0].subNavLinkLabel.text', null));

      return (
        <div
          key={id}
          className="sub-nav-item"
        >
          <NavLink
            className={cn('sub-nav-link', hasSubSubNav && 'has-sub-sub-nav')}
            label={subNavLinkLabel}
            link={subNavLink}
            description={description}
            closeNavHander={closeNavHander}
          />
          {hasSubSubNav && (
            <div className="sub-sub-nav">
              {subSubNav.map(({ subNavLinkLabel: linkLabel, subNavLink: link }) => (
                <NavLink
                  className="sub-sub-nav-link"
                  key={toKebabCase(linkLabel.text)}
                  label={linkLabel}
                  link={link}
                  closeNavHander={closeNavHander}
                />
              ))}
            </div>
          )}
        </div>
      );
    })}
  </div>
);

const NavItem = (props) => {
  const {
    id,
    label,
    link,
    navChildren,
    activeParent,
    showChildrenHandler,
    closeNavHander,
    isMobile,
  } = props;
  const showChildren = Boolean(id === activeParent);
  return (
    <div className={cn('nav-item', showChildren && 'expanded')}>
      <NavLink
        label={label}
        link={link}
        id={id}
        hasChildren={Boolean(navChildren)}
        showChildrenHandler={showChildrenHandler}
        closeNavHander={closeNavHander}
        isMobile={isMobile}
      />
      {navChildren && (
        <div className="sub-nav">
          <Wrapper>
            <SubNav
              navChildren={navChildren}
              closeNavHander={closeNavHander}
            />
          </Wrapper>
        </div>
      )}
    </div>
  );
};

export default NavItem;
