import React from 'react';
import cn from 'classnames';
import { toKebabCase } from '../../utils/helpers';
import { MixedLink } from '.';
import './Breadcrumbs.scss';

const Breadcrumbs = ({ className, currentPageTitle, links }) => (
  <div className={cn(className || null, 'breadcrumbs')}>
    <ul className="crumbs">
      {links.map((crumb) => {
        const {
          pageLabel,
          pageLink,
        } = crumb;
        return (
          <li
            className="crumb"
            key={toKebabCase(pageLabel.text)}
          >
            <MixedLink
              link={pageLink}
              linkLabel={pageLabel}
            />
          </li>
        );
      })}
      <li className="crumb">
        <span>{currentPageTitle?.text}</span>
      </li>
    </ul>
  </div>
);

export default Breadcrumbs;
