import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import cn from 'classnames';
import './Overlay.scss';

export default function Overlay ({ className, show, onClick }) {
  const ref = useRef();

  useEffect(() => {
    gsap.to(ref.current, {
      autoAlpha: show ? 1 : 0,
      duration: 0.25,
      ease: 'sine.inOut',
      pointerEvents: show ? 'all' : 'none',
    });
  }, [show]);

  return (
    <button
      type="button"
      ref={ref}
      className={cn(className || null, 'overlay')}
      onClick={onClick}
    />
  );
}
