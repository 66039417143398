import React from 'react';
import moment from 'moment';
import { Link } from 'gatsby';
import {
  Image,
} from '../common';
import './PostListItem.scss';

const PostListItem = (props) => {
  const {
    uid,
    data,
    blogSlug,
  } = props;
  const {
    postTitle,
    featuredImage,
    featuredImageStyle,
    author,
    date,
  } = data;
  const parsedDate = moment(date);
  const tenDaysAgo = moment().subtract(10, 'days');
  const daysBetween = parsedDate.diff(tenDaysAgo, 'days');
  const isRecent = Boolean(daysBetween > 0);
  const authorData = author.document.data || {};
  const { authorName } = authorData;
  return (
    <div className="post-list-item">
      <Link
        className="container"
        to={`/${blogSlug}/${uid}/`}
        aria-label={`Read more about ${postTitle.text}`}
      >
        { isRecent && (
          <div className="new-post-sticker">
            <span>New</span>
          </div>
        )}
        <div className={`image-container ${featuredImageStyle ? featuredImageStyle.toLowerCase() : ''}`}>
          <Image image={featuredImage} />
        </div>
        <div className="text-container">
          <h2 className="title is-h3">{postTitle.text}</h2>
          <div className="meta-data">
            <span className="author">
              {authorName.text}
            </span>
            <span className="separator" />
            <span className="date-published">
              {date}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default PostListItem;
