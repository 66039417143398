import React from 'react';
import {
  Pagination,
  Categories,
  PostListItem,
} from '.';
import {
  Wrapper,
} from '../common';
import './PostList.scss';

const PostList = (props) => {
  const {
    posts,
    categories,
    siteMetadata,
    location,
    pageContext,
    pathPrefix,
  } = props;
  const { blogSlug } = siteMetadata;
  return (
    <section className="post-list">
      <Wrapper isBound>
        {categories && (
          <Categories
            categories={categories}
            location={location}
            siteMetadata={siteMetadata}
          />
        )}
        <div className="posts">
          {posts.map(post => (
            <PostListItem
              key={post.slug}
              blogSlug={blogSlug}
              {...post}
            />
          ))}
        </div>
        <Pagination
          pageContext={pageContext}
          pathPrefix={pathPrefix}
        />
      </Wrapper>
    </section>
  );
};

export default PostList;
