import React from 'react';
import cn from 'classnames';
import { Wrapper, Breadcrumbs } from '../common';
import { convertColorToTheme } from '../../utils/helpers';
import './BreadcrumbsSlice.scss';

const BreadcrumbsSlice = (props) => {
  const {
    pageTitle,
    data,
    isFirst,
  } = props;
  const {
    primary,
    items: links,
  } = data;
  const {
    theme,
  } = primary;
  const themeClass = theme ? `theme-${convertColorToTheme(theme.toLowerCase())}` : null;
  return (
    <section className={cn('breadcrumbs-slice', themeClass, { isFirst })}>
      <Wrapper>
        <Breadcrumbs
          currentPageTitle={pageTitle}
          links={links}
        />
      </Wrapper>
    </section>
  );
};

export default BreadcrumbsSlice;
