import React from 'react';
import './MessageSpinner.scss';

const MessageSpinner = () => (
  <div className="message-spinner">
    <div className="dot-falling" />
  </div>
);

export default MessageSpinner;
