import React from 'react';
import { Link } from 'gatsby';
import './Pagination.scss';

// const PaginationPages = (props) => {
//   const {
//     pathPrefix,
//     currentPageNumber,
//     pageNumbersArray,
//   } = props;
//   return (
//     <ul className="pages">
//       {pageNumbersArray.map((pageNumber) => {
//         if (pageNumber === currentPageNumber) {
//           return (
//             <li key={pageNumber}>
//               <span
//                 className="page-num active"
//               >
//                 {pageNumber}
//               </span>
//             </li>
//           );
//         }
//         const paginationLink = pageNumber === 1 ? `${pathPrefix}` : `${pathPrefix}/page/${pageNumber}`;
//         return (
//           <li key={pageNumber}>
//             <Link
//               to={paginationLink}
//               className="page-num"
//               aria-label={`Navigate to page ${pageNumber}`}
//             >
//               {pageNumber}
//             </Link>
//           </li>
//         );
//       })}
//     </ul>
//   );
// };


const Pagination = ({ pageContext }) => {
  if (!pageContext) return null;
  const {
    humanPageNumber: currentPageNumber,
    numberOfPages,
    nextPagePath,
    previousPagePath,
  } = pageContext;
  // const pageNumbersArray = Array.from(Array(numberOfPages).keys()).map((val, index) => index + 1);
  if (numberOfPages === 1) return null;
  return (
    <nav className="pagination" role="navigation">
      <div className="pagination-container">
        <div className="col col-left col-prev-page">
          {previousPagePath ? (
            <Link
              to={previousPagePath}
              className="page-stepper prev-page-link"
              aria-label="Navigate to previous page"
            >
              Prev
            </Link>
          ) : (
            <span className="prev-page-link disabled">Prev</span>
          )}
        </div>
        <div className="col col-middle col-page">
          <span
            className="page-num active"
          >
            {`Page ${currentPageNumber} of ${numberOfPages}`}
          </span>
        </div>
        <div className="col col-left col-next-page">
          {nextPagePath ? (
            <Link
              to={nextPagePath}
              className="page-stepper next-page-link"
              aria-label="Navigate to next page"
            >
              Next
            </Link>
          ) : (
            <span className="next-page-link disabled">Next</span>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Pagination;
