import React from 'react';
import { Link } from 'gatsby';
import './Logo.scss';

const Logo = (props) => {
  const {
    clickable,
    collapsed,
    closeNavHander,
  } = props;
  return (
    clickable ? (
      <Link
        className={`logo${collapsed ? ' collapsed' : ''}`}
        to="/"
        aria-label="Return Home"
        onClick={event => closeNavHander(event)}
      >
        <div className="logo-svg" />
      </Link>
    ) : (
      <div
        className={`logo${collapsed ? ' collapsed' : ''}`}
      >
        <div className="logo-svg" />
      </div>
    )
  );
};

export default Logo;
