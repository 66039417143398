import React, { useState, useRef } from 'react';
import { v4 } from 'uuid';
import {
  DIALOGUE_NODES,
  USER_MESSAGE,
} from '../../dialogue';
import {
  Dialogue,
} from '.';
import './DialogueWindow.scss';

const DialogueWindow = ({ closeDialogueHandler }) => {
  const [visibleDialogue, setVisibleDialogue] = useState([{
    ...DIALOGUE_NODES[0],
  }]);
  const [avatarPosition, setAvatarPosition] = useState(0);
  const windowRef = useRef(null);
  const canRevert = visibleDialogue.length >= 3;

  const setNextDialogue = (answer) => {
    const { message, next } = answer;
    const nextNode = DIALOGUE_NODES.find(node => node.label === next);
    const newDialogue = [
      ...visibleDialogue,
      {
        type: USER_MESSAGE,
        messages: [message],
        label: v4(),
      },
    ];
    setVisibleDialogue(newDialogue);
    setTimeout(() => {
      const nextDialogue = [
        ...newDialogue,
        nextNode,
      ];
      setVisibleDialogue(nextDialogue);
    }, 500);
  };

  const revertDialogue = () => {
    if (visibleDialogue.length >= 3) {
      const resetDialogue = visibleDialogue.slice(0, -3);
      const queueDialogue = visibleDialogue.slice(0, -2);
      setVisibleDialogue(resetDialogue);
      setTimeout(() => {
        setVisibleDialogue(queueDialogue);
      }, 500);
    }
  };

  const resetDialogue = () => {
    setVisibleDialogue([]);
    setTimeout(() => {
      setVisibleDialogue([{
        ...DIALOGUE_NODES[0],
      }]);
    }, 500);
  };

  const anchorWindowToBottom = () => {
    const scrollHeight = windowRef && windowRef.current ? windowRef.current.scrollHeight || null : null;
    if (scrollHeight) {
      windowRef.current.scrollTop = scrollHeight;
    }
  };

  return (
    <section className="dialogue-window" ref={windowRef}>
      <div className="dialog-header">
        <svg viewBox="0 0 52 63" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.953 1.201l24.821 8.992v50.989H1.139V10.193l24.814-8.992zm0-1.201l-.38.139L0 9.399v52.914h51.903V9.4L26.336.139 25.956 0h-.003z" fill="#FFF" />
          <path d="M46.117 14.748l-6.758 41.896h-8.38l-5.026-24.482-5.028 24.482h-8.38L5.783 14.748h7.54l3.89 29.388 5.388-24.959h6.703l5.386 24.959 3.89-29.388h7.537z" fill="#FFF" />
        </svg>
        <h3 className="header-title">
          We're Here For You
        </h3>
        <button
          className="close-button"
          type="button"
          onClick={() => closeDialogueHandler()}
          aria-label="Close the dialogue window"
        >
          <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M25.927 10.653a1 1 0 00-1.414-1.414l-7.049 7.049-7.048-7.049A1 1 0 109 10.653l7.05 7.05-6.879 6.877a1 1 0 001.414 1.414l6.878-6.878 6.878 6.878a1 1 0 001.415-1.414l-6.879-6.878 7.05-7.049z" fill="#FFF" />
          </svg>
        </button>
      </div>
      <div className="dialog-body" data-body-scroll-lock-ignore>
        {visibleDialogue && visibleDialogue.length > 0 && visibleDialogue.map(((interaction, index) => (
          <Dialogue
            key={interaction.label}
            isCurrentDialogue={index === visibleDialogue.length - 1}
            interaction={interaction}
            canRevert={canRevert}
            setNextDialogue={setNextDialogue}
            revertDialogue={revertDialogue}
            setAvatarPosition={setAvatarPosition}
            anchorWindowToBottom={anchorWindowToBottom}
            resetDialogueHandler={resetDialogue}
          />
        )))}
        <div className="wagec-avatar" style={{ transform: `translateY(${avatarPosition}px)` }}>
          <svg viewBox="0 0 52 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.953 1.201l24.821 8.992v50.989H1.139V10.193l24.814-8.992zm0-1.201l-.38.139L0 9.399v52.914h51.903V9.4L26.336.139 25.956 0h-.003z" fill="#2D235F" />
            <path d="M46.117 14.748l-6.758 41.896h-8.38l-5.026-24.482-5.028 24.482h-8.38L5.783 14.748h7.54l3.89 29.388 5.388-24.959h6.703l5.386 24.959 3.89-29.388h7.537z" fill="#2D235F" />
          </svg>
        </div>
      </div>
    </section>
  );
};

export default DialogueWindow;
