import React from 'react';
import './MobileNavigationButton.scss';

const MobileNavigationButton = (props) => {
  const {
    navOpen,
    toggleNavHandler,
  } = props;
  return (
    <button
      className={`hamburger hamburger--stack ${navOpen ? 'active' : ''}`}
      type="button"
      aria-label="Toggle Navigation"
      onClick={event => toggleNavHandler(event)}
    >
      <div className="inner">
        <span className="bar" />
        <span className="bar" />
        <span className="bar" />
      </div>
    </button>
  );
};

export default MobileNavigationButton;
