import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import { SliderButtons } from '../Slider';
import { Wrapper } from '../common';
import { convertColorToTheme, isClient } from '../../utils/helpers';
import './DocumentCarousel.scss';

const Document = (props) => {
  const {
    tileTheme = 'purple',
    documentFile,
    documentTitle,
    year,
  } = props;
  const {
    url,
  } = documentFile;
  const documentClasses = `document theme-${convertColorToTheme(tileTheme?.toLowerCase() || 'purple')}`;
  return (
    <a
      className={documentClasses}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="text-container">
        <div className="label">
          {documentTitle.text}
        </div>
        <div className="year">
          {year.text}
        </div>
      </div>
    </a>
  );
};

const DocumentCarousel = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
    items: documents,
  } = data;
  if (!documents) return null;
  const {
    theme,
    headline,
    subtitle,
  } = primary;
  const themeClass = convertColorToTheme(theme.toLowerCase());
  const ref = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: index => setActiveIndex(index),
  };
  const goToNextSlide = (event) => {
    event.preventDefault();
    ref.current.slickNext();
  };
  const goToPrevSlide = (event) => {
    event.preventDefault();
    ref.current.slickPrev();
  };
  let slidesToShow = 3.5;
  const viewportWidth = isClient ? window.innerWidth : 1600;
  if (viewportWidth <= 1440) slidesToShow = 2.5;
  if (viewportWidth <= 800) slidesToShow = 1.5;
  const showNextButton = Boolean(activeIndex >= ((documents.length) - Math.floor(slidesToShow)));
  const showPrevButton = Boolean(activeIndex === 0);
  return (
    <section className={`document-carousel theme-${themeClass}`}>
      <Wrapper>
        <div className="intro-text">
          <p className="subtitle">{subtitle.text}</p>
          <h2 className="headline">{headline.text}</h2>
        </div>
      </Wrapper>
      <div className="slider-container">
        <SliderButtons
          showNextButton={showNextButton}
          showPrevButton={showPrevButton}
          goToNextSlideHandler={goToNextSlide}
          goToPrevSlideHandler={goToPrevSlide}
        />
        <div className="documents">
          <Slider ref={ref} {...settings}>
            {documents && documents.map(doc => (
              <Document
                key={doc.documentFile.url}
                {...doc}
              />
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default DocumentCarousel;
