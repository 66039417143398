import React from 'react';
import { HtmlContent } from '../common';
import './PostFeaturedText.scss';

const PostFeaturedText = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
  } = data;
  const {
    textBody,
  } = primary;
  return (
    <section className="post-featured-text">
      <HtmlContent content={textBody.html} />
    </section>
  );
};

export default PostFeaturedText;
