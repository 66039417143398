import React from 'react';
import { Wrapper, Image } from '../common';
import './PostHero.scss';

const PostHero = (props) => {
  const {
    headline,
    author,
    publishedDate,
    featuredImage,
    featuredImageStyle = 'cover',
  } = props;
  return (
    <section className="post-hero">
      <Wrapper>
        <div className="cols">
          <div className="col col-left">
            <div className={`featured-image ${featuredImageStyle?.toLowerCase()}`}>
              <div className="image-container">
                <Image image={featuredImage} className={featuredImageStyle?.toLowerCase()} />
              </div>
            </div>
          </div>
          <div className="col col-right">
            <div className="text-container">
              <h1 className="headline">{headline.text}</h1>
              {author && <p className="author">{`By ${author.text}`}</p>}
              <span className="published-date">{`Published on ${publishedDate}`}</span>
            </div>
          </div>
        </div>
      </Wrapper>
    </section>
  );
};

export default PostHero;
