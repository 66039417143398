import React from 'react';
import { Link } from 'gatsby';
import {
  Wrapper,
} from '../common';
import { convertColorToTheme } from '../../utils/helpers';
import './TextAndLinksBlock.scss';

const Questions = ({ questions }) => (
  <div className="questions">
    {questions.map((question, index) => {
      const {
        questionLabel,
        questionLink,
      } = question;
      const {
        url,
      } = questionLink;
      return (
        <div
          className="question"
          key={index}
        >
          <a href={url}>
            <span>{questionLabel.text}</span>
            <div className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 26">
                <path fill="none" fillRule="nonzero" stroke="#FAF0DC" strokeWidth="1.5" d="M2.2433 16.3757h24.8045l-6.5336 7.4335c-.4304.4695-.0783 1.252.5477 1.252h6.964c.1957 0 .4304-.0783.5478-.2348l9.8983-11.3068c.2347-.2738.2347-.7042 0-.978L28.5737 1.2346C28.4172 1.0782 28.2216 1 28.0259 1h-6.964c-.626 0-.978.7434-.5477 1.252l6.5336 7.4335H2.2433c-.3912 0-.7433.3521-.7433.7433v5.2426c0 .3521.3521.7043.7433.7043z" />
              </svg>
            </div>
          </a>
        </div>
      );
    })}
  </div>
);

const TextAndLinksBlock = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
    items: questions,
  } = data;
  const {
    headline,
    subtitle,
    buttonLabel,
    buttonLink,
    theme,
  } = primary;
  const themeClass = theme.toLowerCase();
  return (
    <section className={`text-and-links-block ${themeClass}`}>
      <Wrapper isBound>
        <div className="cols">
          <div className="col col-left">
            <div className="text-container">
              <p className="subtitle">{subtitle.text}</p>
              <h2 className="headline">{headline.text}</h2>
              <div className="buttons">
                <Link
                  to={`/${buttonLink.slug}/`}
                  className={`button styled-button styled-button-${convertColorToTheme(themeClass)} color-invert`}
                >
                  {buttonLabel.text}
                </Link>
              </div>
            </div>
          </div>
          <div className="col col-right">
            <div className="questions-container">
              {questions && questions.length > 0 && (
                <div className="questions">
                  <Questions
                    questions={questions}
                    theme={themeClass}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Wrapper>
    </section>
  );
};

export default TextAndLinksBlock;
