export const USER_MESSAGE = 'USER_MESSAGE';
export const WAGEC_MESSAGE = 'WAGEC_MESSAGE';

export const DIALOGUE_NODES = [
  {
    label: 'intro',
    type: WAGEC_MESSAGE,
    messages: [
      `<p>We're here for you and understand that certain situations can be overwhelming.</p>`,
      '<p>Use the service below to find the best resources and information about domestic violence and healthy relationships for you or someone you are supporting.</p>',
    ],
    answers: [
      {
        message: 'Get Started',
        next: 'beginning',
      },
    ],
  },
  {
    label: 'beginning',
    type: WAGEC_MESSAGE,
    messages: [
      '<p>Are you enquiring about yourself or someone you care about?</p>',
    ],
    answers: [
      {
        message: 'Myself',
        next: 'myself_a',
      },
      {
        message: 'Someone I care about',
        next: 'someone_a',
      },
    ],
  },
  {
    label: 'myself_a',
    type: WAGEC_MESSAGE,
    messages: [
      '<p>Are you or your children in immediate danger of violence within your home?</p>',
    ],
    answers: [
      {
        message: 'Yes',
        next: 'myself_aa',
      },
      {
        message: 'No',
        next: 'myself_ab',
      },
    ],
  },
  {
    label: 'myself_aa',
    type: WAGEC_MESSAGE,
    messages: [
      '<p><strong>CALL</strong></p><p>Police and Ambulance: Call <a href="tel:000">000</a> if you are in immediate danger. (AUS)</p>',
      '<p><strong>For support while you wait call:</strong></p>',
      '<p><strong>1800 RESPECT</strong></p><p>Call <a href="tel:1800 737 732">1800 737 732</a> for confidential counselling and support. (AUS)</p>',
      '<p><strong>DOMESTIC VIOLENCE LINE</strong></p><p>Call <a href="tel:1800 656 463">1800 656 463</a> for a 24/7 intervention service including crisis counselling and advocacy for women with or without children</p>',
    ],
  },
  {
    label: 'myself_ab',
    type: WAGEC_MESSAGE,
    messages: [
      '<p>Here are some warning signs that you may be experiencing domestic and family violence.</p>',
      '<p>A person:</p>',
      '<ul><li>Deliberately hurts you</li><li>Tries to control your money, what you eat, what you wear, or what you do on a daily basis</li><li>Controls or monitors your technology (phone, computer or internet searches)</li><li>Isolates you from your family, friends or workplace</li><li>Expresses extreme jealously or demands to know your movements on a daily basis</li><li>Threatens to hurt you, your children or themselves</li></ul>',
      '<p>Have you recently experienced any of these behaviours?</p>',
    ],
    answers: [
      {
        message: 'Yes',
        next: 'myself_aba',
      },
      {
        message: 'Sometimes',
        next: 'myself_abb',
      },
      {
        message: 'No',
        next: 'myself_abc',
      },
    ],
  },
  {
    label: 'myself_aba',
    type: WAGEC_MESSAGE,
    messages: [
      '<p>Have you thought about leaving the relationship or home?</p>',
    ],
    answers: [
      {
        message: 'Yes',
        next: 'myself_abaa',
      },
      {
        message: 'No',
        next: 'myself_abab',
      },
    ],
  },
  {
    label: 'myself_abaa',
    type: WAGEC_MESSAGE,
    messages: [
      '<p><a href="/frequently-asked-questions/?question=what-are-my-options-after-leaving-a-violent-situation">Learn about your options after you leave a violent situation</a></p>',
      '<p><a href="/frequently-asked-questions/?question=what-are-my-legal-rights">Learn about the legal rights</a> in place to protect victims domestic and family violence</p>',
      '<p><a href="/contact">Click here</a> to book a time to talk to a DV specialist councilor or call <a href="tel:1800 737 732">1800 RESPECT (1800 737 732)</a> for immediate confidential counselling and support (AUS)</p>',
    ],
  },
  {
    label: 'myself_abab',
    type: WAGEC_MESSAGE,
    messages: [
      '<p><a href="/frequently-asked-questions/?question=what-are-some-signs-of-an-unhealthy-relationship">Click here to learn about coercive control</a></p>',
      '<p><a href="/frequently-asked-questions/?question=have-you-thought-about-leaving-the-relationship-or-home">Learn how to make a safety plan</a></p>',
      '<p><a href="/contact">Click here</a> to book a time to talk to a DV specialist councilor or call <a href="tel:1800 737 732">1800 RESPECT (1800 737 732)</a> for immediate confidential counselling and support (AUS)</p>',
    ],
  },
  {
    label: 'myself_abb',
    type: WAGEC_MESSAGE,
    messages: [
      '<p><a href="/frequently-asked-questions/?question=what-is-the-cycle-of-violence">Click here to learn about the cycle of violence</a></p>',
      '<p><a href="/frequently-asked-questions/?question=who-does-domestic-and-family-violence-affect">Click here to learn about domestic and family violence and who it affects</a></p>',
      '<p><a href="/contact">Click here</a> to book a time to talk to a DV specialist councilor or call <a href="tel:1800 737 732">1800 RESPECT (1800 737 732)</a> for immediate confidential counselling and support (AUS)</p>',
    ],
  },
  {
    label: 'myself_abc',
    type: WAGEC_MESSAGE,
    messages: [
      '<p><a href="/frequently-asked-questions/?question=what-are-some-signs-of-an-unhealthy-relationship">Click here to learn about coercive control</a></p>',
      '<p><a href="/frequently-asked-questions/?question=who-does-domestic-and-family-violence-affect">Click here to learn about domestic and family violence and who it affects</a></p>',
      '<p><a href="/contact">Click here</a> to book a time to talk to a DV specialist councilor or call <a href="tel:1800 737 732">1800 RESPECT (1800 737 732)</a> for immediate confidential counselling and support (AUS)</p>',
    ],
  },
  {
    label: 'someone_a',
    type: WAGEC_MESSAGE,
    messages: [
      '<p>Are they or their children in immediate danger of violence within their home?</p>',
    ],
    answers: [
      {
        message: 'Yes',
        next: 'myself_aa',
      },
      {
        message: 'No',
        next: 'someone_ab',
      },
    ],
  },
  {
    label: 'someone_ab',
    type: WAGEC_MESSAGE,
    messages: [
      '<p>Here are some warning signs that you may be experiencing domestic and family violence.</p>',
      '<p>A person:</p>',
      '<ul><li>Deliberately hurts you</li><li>Tries to control your money, what you eat, what you wear, or what you do on a daily basis</li><li>Controls or monitors your technology (phone, computer or internet searches)</li><li>Isolates you from your family, friends or workplace</li><li>Expresses extreme jealously or demands to know your movements on a daily basis</li><li>Threatens to hurt you, your children or themselves</li></ul>',
      '<p>Have you recently observed any of these behaviours?</p>',
    ],
    answers: [
      {
        message: 'Yes',
        next: 'someone_aba',
      },
      {
        message: 'Sometimes',
        next: 'someone_abb',
      },
      {
        message: 'No',
        next: 'someone_abc',
      },
    ],
  },
  {
    label: 'someone_aba',
    type: WAGEC_MESSAGE,
    messages: [
      '<p><a href="/frequently-asked-questions/?question=what-are-some-signs-of-an-unhealthy-relationship">Click here to learn about coercive control</a></p>',
      '<p><a href="/frequently-asked-questions/?question=who-does-domestic-and-family-violence-affect">Click here to learn about domestic and family violence and who it affects</a></p>',
      '<p>Call <a href="tel:1800 737 732">1800 RESPECT (1800 737 732)</a> for immediate confidential counselling and support (AUS)</p>',
    ],
  },
  {
    label: 'someone_abb',
    type: WAGEC_MESSAGE,
    messages: [
      '<p><a href="/frequently-asked-questions/?question=what-are-some-signs-of-an-unhealthy-relationship">Click here to learn about coercive control</a></p>',
      '<p><a href="/frequently-asked-questions/?question=who-does-domestic-and-family-violence-affect">Click here to learn about domestic and family violence and who it affects</a></p>',
      '<p><a href="/frequently-asked-questions/?question=what-is-the-cycle-of-violence">Click here to learn about the cycle of violence</a></p>',
      '<p>Call <a href="tel:1800 737 732">1800 RESPECT (1800 737 732)</a> for immediate confidential counselling and support (AUS)</p>',
    ],
  },
  {
    label: 'someone_abc',
    type: WAGEC_MESSAGE,
    messages: [
      '<p><a href="/frequently-asked-questions/?question=what-are-my-legal-rights">Learn about the legal rights</a> in place to protect victims domestic and family violence</p>',
      '<p><a href="/frequently-asked-questions/?question=how-can-i-support-someone-experiencing-domestic-and-family-violence">Click here to learn about how you can support someone experiencing domestic and family violence</a></p>',
      '<p><a href="/frequently-asked-questions/?question=who-does-domestic-and-family-violence-affect">Click here to learn about domestic and family violence and who it affects</a></p>',
      '<p>Call <a href="tel:1800 737 732">1800 RESPECT (1800 737 732)</a> for immediate confidential counselling and support (AUS)</p>',
    ],
  },
];
