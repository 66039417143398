import React from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import {
  Buttons,
  Image,
  HtmlContent,
  Wrapper,
  MixedLink,
} from '../common';
import { convertColorToTheme } from '../../utils/helpers';
import { ReactComponent as ArrowRightIcon } from '../icons/arrow-right-icon.svg';
import './ResourceTiles.scss';

const Tile = ({
  image,
  title,
  description,
  buttonLink,
  buttonLabel,
}) => {
  const buttonChildren = buttonLabel?.text ? (
    <>
      <span>{buttonLabel?.text}</span>
      <ArrowRightIcon />
    </>
  ) : null;
  const hasButtons = get(buttonLabel, 'text');
  const buttons = [{
    buttonLink,
    buttonLabel,
    buttonChildren,
  }];
  const aspectRatio = get(image, 'fluid.aspectRatio', 1);
  const content = (
    <>
      <div
        className="image-container"
        style={{ paddingBottom: `${100 / aspectRatio}%` }}
      >
        <Image className="image" image={image} />
      </div>
      <HtmlContent className="title" content={title.html} />
      <HtmlContent className="description" content={description.html} />
      {hasButtons && (
        <Buttons
          buttons={buttons}
          styled={false}
        />
      )}
    </>
  );
  return (
    hasButtons ? (
      <MixedLink
        link={buttonLink}
        linkLabel={buttonLabel}
        className="tile has-hover"
      >
        {content}
      </MixedLink>
    ) : (
      <div className="tile">
        {content}
      </div>
    )
  );
};

const ResourceTiles = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
    items: tiles,
  } = data;
  const {
    theme,
  } = primary;
  const themeClass = `theme-${convertColorToTheme(theme.toLowerCase())}`;
  if (!tiles || tiles.length < 1) return null;
  return (
    <section className={cn('resource-tiles', themeClass)}>
      <Wrapper isBound>
        <div className="tiles">
          {tiles.map((tile, index) => (
            <Tile
              key={index}
              {...tile}
            />
          ))}
        </div>
      </Wrapper>
    </section>
  );
};

export default ResourceTiles;
