import React from 'react';
import {
  Buttons,
  Image,
  HtmlContent,
} from '../common';
import { convertColorToTheme } from '../../utils/helpers';
import './GridBlock.scss';

const GridBlock = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
    items: buttons,
  } = data;
  const {
    headline,
    description,
    photo,
    theme,
    photoAlignment,
    grayscale,
  } = primary;
  const themeClass = convertColorToTheme(theme.toLowerCase());
  return (
    <section className={`grid-block theme-${themeClass}`}>
      <div className={`cols photo-align-${photoAlignment.toLowerCase()}`}>
        <div className="col text-col">
          <div className="half-wrap">
            <div className="text-container">
              <h2 className="headline">{headline.text}</h2>
              <HtmlContent
                className="description"
                content={description.html}
              />
              {buttons && buttons.length > 0 && (
                <Buttons
                  buttons={buttons}
                  theme={themeClass}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col photo-col">
          <div className={`photo${grayscale ? ' grayscale' : ''}`}>
            <Image image={photo} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default GridBlock;
