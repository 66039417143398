import React, { Component, useState } from 'react';
import Collapse from 'react-css-collapse';
import { StaticQuery, graphql } from 'gatsby';
import { Wrapper } from '../common';
import './HotlineAccordion.scss';

const Hotline = (props) => {
  const {
    hotlineName,
    telephoneNumber,
    description,
    hotlineWebsite,
  } = props;
  const hasTelephoneNumber = Boolean(telephoneNumber && telephoneNumber.text);
  const hasWebsite = Boolean(hotlineWebsite && hotlineWebsite.url);
  return (
    <div className="hotline">
      <h4 className="hotline-name">{hotlineName.text}</h4>
      { hasTelephoneNumber && telephoneNumber && telephoneNumber.text && (
        <a
          className="telephone"
          href={`tel:${telephoneNumber.text}`}
        >
          <span className="srOnly">{`Call ${hotlineName.text}: `}</span>
          {telephoneNumber.text.split('').map((digit, index) => <span key={`${digit}-${index}`}>{digit}</span>)}
        </a>
      )}
      <p className="description">
        {description.text}
      </p>
      { hasWebsite && (
        <a
          className="website-link"
          href={hotlineWebsite.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>Visit <span className="srOnly">{`the ${hotlineName.text}: `}</span>website</span>
        </a>
      )}
    </div>
  );
};

const HotlineCategory = (props) => {
  const {
    primary,
    items: hotlines,
  } = props;
  const {
    categoryName,
  } = primary;
  if (!hotlines || hotlines.length < 1) return null;
  const [expanded, setExpanded] = useState(false);
  return (
    <div className={`hotline-category ${expanded ? 'expanded' : ''}`}>
      <button
        type="button"
        className="toggle-button"
        onClick={() => setExpanded(!expanded)}
        aria-label={`${!expanded ? 'Show' : 'Hide'} hotlines categorized under ${categoryName.text}`}
      >
        <h3 className="category-title">{categoryName.text}</h3>
        <div className={`icon ${expanded ? 'toggled' : ''}`} />
      </button>
      <Collapse isOpen={expanded}>
        <div className="hotlines">
          { hotlines.map(hotline => (
            <Hotline
              {...hotline}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
};

const HotlineAccordionTemplate = (props) => {
  const {
    data,
    hotlineCategories,
  } = props;
  const {
    primary,
  } = data;
  const {
    title,
    subtitle,
  } = primary;
  if (!hotlineCategories || hotlineCategories.length < 1) return null;
  return (
    <section className="hotline-accordion">
      <Wrapper isBound>
        <div className="intro-text">
          <p className="subtitle">
            {subtitle.text}
          </p>
          <h2 className="title">
            {title.text}
          </h2>
        </div>
        <div className="emergency-contacts">
          <div className="emergency-services-details">
            <p>
              <span>Call</span>
              <a href="tel:000">
                <span className="srOnly">Call: </span>
                <span>0</span>
                <span>0</span>
                <span>0</span>
              </a>
              <span>for Police and Ambulance help if you are immediate danger.</span>
            </p>
          </div>
        </div>
        <div className="container">
          { hotlineCategories.map(hotlineCategory => (
            <HotlineCategory
              {...hotlineCategory}
            />
          ))}
        </div>
      </Wrapper>
    </section>
  );
};

class HotlineAccordion extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query HotlineAccordionQuery {
            hotlineCategories: prismicHotlines {
              data {
                body {
                  ... on PrismicHotlinesBodyHotlineCategory {
                    primary {
                      categoryName: category_name {
                        text
                      }
                    }
                    items {
                      telephoneNumber: telephone_number {
                        text
                      }
                      hotlineWebsite: hotline_website {
                        url
                      }
                      hotlineName: hotline_name {
                        text
                      }
                      description {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <HotlineAccordionTemplate
            {...this.props}
            hotlineCategories={data.hotlineCategories.data.body}
          />
        )}
      />
    );
  }
}

export default HotlineAccordion;
