import React, { Component } from 'react';
import './ContactForm.scss';

const FORM_KEY_NAME = 'NAME';
const FORM_KEY_PHONE = 'PHONE';
const FORM_KEY_EMAIL = 'EMAIL';
const FORM_KEY_MESSAGE = 'MESSAGE';

class ContactForm extends Component {
  state = {
    fieldValues: {},
  }

  handleChange = (event, fieldKey) => {
    const { fieldValues } = this.state;
    this.setState({
      fieldValues: {
        ...fieldValues,
        [fieldKey]: event.target.value,
      },
    });
  }

  render() {
    const {
      formName,
    } = this.props;
    return (
      <form
        className="contact-form"
        name={formName}
        method="POST"
        action="/thank-you/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value={formName} />
        <div style={{ display: 'none' }}>
          <label htmlFor="bot-field">Don’t fill this out:</label> {/* eslint-disable-line */}
          <input name="bot-field" type="text" id="name" placeholder="Don’t fill this out!" />
        </div>
        <div className="field full-width">
          <label className="field-label" htmlFor="name">Name</label> {/* eslint-disable-line */}
          <input
            type="text"
            id="name"
            name="name"
            onChange={event => this.handleChange(event, FORM_KEY_NAME)}
            required
          />
        </div>
        <div className="field full-width">
          <label className="field-label" htmlFor="phone">Phone number</label> {/* eslint-disable-line */}
          <input
            type="tel"
            pattern="^\+?\d{0,13}"
            id="phone"
            name="phone"
            onChange={event => this.handleChange(event, FORM_KEY_PHONE)}
            required
          />
        </div>
        <div className="field full-width">
          <label className="field-label" htmlFor="email">Email</label> {/* eslint-disable-line */}
          <input
            type="email"
            id="email"
            name="email"
            onChange={event => this.handleChange(event, FORM_KEY_EMAIL)}
            required
          />
        </div>
        <div className="field full-width">
          <label className="field-label" htmlFor="message">Message</label> {/* eslint-disable-line */}
          <textarea
            id="message"
            name="message"
            rows="6"
            onChange={event => this.handleChange(event, FORM_KEY_MESSAGE)}
          />
        </div>
        <div className="form-actions">
          <button
            className="button styled-button"
            type="submit"
            aria-label="Submit Form"
          >
            <span>Send</span>
          </button>
        </div>
      </form>
    );
  }
}

export default ContactForm;
