import React from 'react';
import { Wrapper, HtmlContent } from '.';
import { convertColorToTheme } from '../../utils/helpers';
import './EmergencyDisclaimer.scss';

const EmergencyDisclaimer = ({
  title,
  content,
  bannerTheme,
  bannerStyle,
}) => (
  <section className={`emergency-disclaimer ${bannerStyle.toLowerCase()} theme-${convertColorToTheme(bannerTheme.toLowerCase())}`}>
    <Wrapper isBound>
      <h2 className="title">{title.text}</h2>
      <HtmlContent
        content={content.html}
      />
    </Wrapper>
  </section>
);

export default EmergencyDisclaimer;
