import React from 'react';
import { VideoBlock } from '../common';
import './PostVideo.scss';

const PostVideo = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
  } = data;
  const {
    videoProvider,
    videoUrl,
    caption,
  } = primary;
  return (
    <section className="post-video">
      <VideoBlock
        videoProvider={videoProvider.toLowerCase()}
        videoUrl={videoUrl.url}
      />
      <span className="caption">{caption.text}</span>
    </section>
  );
};

export default PostVideo;
