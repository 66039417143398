import React from 'react';
import {
  Image,
  HtmlContent,
  Buttons,
} from '../common';
import { convertColorToTheme } from '../../utils/helpers';
import './ImageTextBlock.scss';

const ImageTextBlock = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
    items: buttons,
  } = data;
  const {
    headline,
    subtitle,
    description,
    photo,
    theme,
  } = primary;
  const themeClass = convertColorToTheme(theme.toLowerCase());
  return (
    <section className={`image-text-block theme-${themeClass}`}>
      <div className="cols">
        <div className="col col-left">
          <div className="half-wrap half-wrap-left">
            <div className="text-container">
              <p className="subtitle">{subtitle.text}</p>
              <h2 className="headline">{headline.text}</h2>
              <HtmlContent
                className="description"
                content={description.html}
              />
              {buttons && buttons.length > 0 && (
                <Buttons
                  buttons={buttons}
                  theme={themeClass}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col col-right">
          <div className="photo">
            <Image image={photo} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImageTextBlock;
