import React from 'react';
import './SocialLink.scss';

const FACEBOOK_TYPE = 'Facebook';
const INSTAGRAM_TYPE = 'Instagram';
const LINKEDIN_TYPE = 'LinkedIn';

const SocialLink = (props) => {
  const {
    socialLink,
  } = props;
  const {
    type,
    link,
  } = socialLink;
  const { url } = link;
  const typeClass = type.replace(/\s+/g, '-').toLowerCase();
  switch (type) {
    case FACEBOOK_TYPE:
      return (
        <a
          className={`social-link ${typeClass}`}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Open a new tab to Facebook"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 33">
            <path fill="#FFF" fillRule="nonzero" d="M18.655.137l-4.9182.0022s-.0997-.0075-.2789-.0075c-1.0091 0-6.1123.2804-7.5008 5.7525-.0217.0607-.4888 1.4305-.4963 4.616H.7954a.75.75 0 0 0-.7497.7497v4.2988a.75.75 0 0 0 .7497.7497h5.1025v15.4394a.75.75 0 0 0 .7497.7498H12.49a.75.75 0 0 0 .7497-.7498V16.3141h5.119a.75.75 0 0 0 .7497-.7497v-4.3145a.75.75 0 0 0-.7497-.7497h-5.1086v-1.845c0-1.2289.7797-2.5416 2.969-2.5416h2.4357a.75.75 0 0 0 .7497-.7497V.8867a.7495.7495 0 0 0-.7497-.7497zm-.7498 4.4772h-1.686c-3.0867 0-4.4683 2.0295-4.4683 4.041v2.5947a.75.75 0 0 0 .7496.7497h5.1085v2.8152H12.49a.75.75 0 0 0-.7497.7497v15.4237h-4.343V15.5487a.75.75 0 0 0-.7497-.7497H1.545v-2.7994h4.6714a.7488.7488 0 0 0 .5353-.2249.751.751 0 0 0 .2144-.5398c-.0667-3.4096.4019-4.8453.4244-4.918 1.1058-4.3476 4.924-4.6858 6.0673-4.6858.1042 0 .1665.003.2272.0053h4.22v2.9778z" />
          </svg>
        </a>
      );
    case LINKEDIN_TYPE:
      return (
        <a
          className={`social-link ${typeClass}`}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Open a new tab to LinkedIn"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 33">
            <path fill="#FFF" fillRule="nonzero" d="M8.1305 9.245H1.831a.7808.7808 0 0 0-.7805.7805v22.0218c0 .4308.3497.7805.7805.7805h6.3005a.7808.7808 0 0 0 .7805-.7805V10.0255a.781.781 0 0 0-.7814-.7805zM7.35 31.2668H2.6107V10.806H7.35v20.4607zM25.6956 8.9773c-3.059 0-5.0602 1.3199-6.2224 2.468l-.1436-1.4947a.781.781 0 0 0-.7767-.7056h-.0156l-5.7431.11a.7778.7778 0 0 0-.5542.2467.7803.7803 0 0 0-.2107.569c.0718 1.608.0695 3.4874.0663 5.6635l-.0008 16.324c0 .431.3497.7805.7805.7805h6.3005a.7808.7808 0 0 0 .7805-.7805V19.4278c0-.6502.0562-1.12.1678-1.4003.3927-.9998 1.6883-2.0333 3.176-2.0333 1.9271 0 3.0768 1.4658 3.0768 3.9222v12.2418c0 .4309.3498.7805.7805.7805h6.1896a.7808.7808 0 0 0 .7806-.7805v-13.041c0-6.3497-3.1525-10.14-8.432-10.14zm6.871 22.4004H27.938V19.9164c0-3.3313-1.821-5.4832-4.638-5.4832-2.2354 0-4.0532 1.5579-4.6269 3.0198-.1897.4738-.2778 1.1022-.2778 1.9748v11.95h-4.7394l.0008-15.5412c.0024-1.8484.0047-3.4843-.0382-4.9361l4.2265-.0812.2428 2.5172a.7812.7812 0 0 0 .7774.7056h.1327a.7795.7795 0 0 0 .662-.3668c.4604-.736 2.2564-3.137 6.0365-3.137 4.367 0 6.871 3.1268 6.871 8.5789v12.2605h-.0009zM5.245.0613C2.7114.0613.8724 1.8073.8724 4.2137c0 2.4048 1.8023 4.15 4.2851 4.15 2.5594 0 4.417-1.7452 4.417-4.1687C9.5175 1.7613 7.7372.0613 5.245.0613zm-.0875 6.7414c-1.604 0-2.724-1.0646-2.724-2.589 0-1.5501 1.1302-2.5913 2.8115-2.5913 1.6445 0 2.7318 1.024 2.7685 2.5913 0 1.5486-1.1482 2.589-2.856 2.589z" />
          </svg>
        </a>
      );
    case INSTAGRAM_TYPE:
      return (
        <a
          className={`social-link ${typeClass}`}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Open a new tab to Instagram"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 35">
            <path fill="#FFF" fillRule="nonzero" d="M26.6397.9906C30.7047.9906 34 4.2859 34 8.3508v19.2795c0 4.065-3.2953 7.3603-7.3603 7.3603H7.3603C3.2953 34.9906 0 31.6953 0 27.6303V8.3508C0 4.2858 3.2953.9906 7.3603.9906h19.2794zm0 1.38H7.3603c-3.3028 0-5.9803 2.6775-5.9803 5.9802v19.2795c0 3.3028 2.6775 5.9802 5.9803 5.9802h19.2794c3.3028 0 5.9803-2.6774 5.9803-5.9802V8.3508c0-3.3027-2.6775-5.9802-5.9803-5.9802zM17 9.5402c4.667 0 8.4503 3.7834 8.4503 8.4504S21.667 26.4409 17 26.4409s-8.4503-3.7833-8.4503-8.4503S12.333 9.5402 17 9.5402zm0 1.38c-3.9048 0-7.0703 3.1656-7.0703 7.0704 0 3.9048 3.1655 7.0703 7.0703 7.0703 3.9048 0 7.0703-3.1655 7.0703-7.0703 0-3.9048-3.1655-7.0703-7.0703-7.0703zm9.1251-5.9888c1.6655 0 3.0156 1.3501 3.0156 3.0156 0 1.6654-1.3501 3.0155-3.0156 3.0155-1.6654 0-3.0155-1.35-3.0155-3.0155 0-1.6655 1.3501-3.0156 3.0155-3.0156zm0 1.38c-.9032 0-1.6355.7323-1.6355 1.6356 0 .9032.7323 1.6355 1.6355 1.6355.9033 0 1.6356-.7323 1.6356-1.6355 0-.9033-.7323-1.6355-1.6356-1.6355z" />
          </svg>
        </a>
      );
    default:
      return null;
  }
};

export default SocialLink;
