import React,
{
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import { VideoBlock, Wrapper, Buttons } from '../common';
import { convertColorToTheme } from '../../utils/helpers';
import useWindowResize from '../../hooks/useWindowResize';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import './VideoBlock.scss';

const VideoBlockSlice = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
    items: buttons,
  } = data;
  const {
    theme,
    videoProvider,
    videoUrl,
  } = primary;
  const hasButtons = get(buttons, '0.buttonLabel.text');
  const themeClass = `theme-${convertColorToTheme(theme.toLowerCase())}`;

  const videoContainerRef = useRef();
  const [maxWidth, setMaxWidth] = useState(1440);

  const measure = useCallback((ref) => {
    if (ref.current) {
      const aspectRatio = 16 / 9;
      const { height: maxHeight } = videoContainerRef.current.getBoundingClientRect();
      const calculatedMaxWidth = maxHeight * aspectRatio;
      setMaxWidth(calculatedMaxWidth);
    }
  }, []);

  useEffect(() => measure(videoContainerRef), []);
  useWindowResize(useDebouncedCallback(() => measure(videoContainerRef)));

  // Add logic to have the width set to a maximum of 16/9 * (viewportHeight - headerSize)

  return (
    <section className={cn('video-block-slice', themeClass)}>
      <Wrapper isBound>
        <div
          ref={videoContainerRef}
          className="video-container"
          style={{ maxWidth: `${maxWidth}px` }}
        >
          <VideoBlock
            className="video"
            videoProvider={videoProvider.toLowerCase()}
            videoUrl={videoUrl.url}
          />
        </div>
        {hasButtons && (
          <Buttons
            buttons={buttons}
          />
        )}
      </Wrapper>
    </section>
  );
};

export default VideoBlockSlice;
