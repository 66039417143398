import React, { useState } from 'react';
import Collapse from 'react-css-collapse';
import { Wrapper, HtmlContent } from '../common';
import { convertColorToTheme } from '../../utils/helpers';
import './CollapsibleContentBlock.scss';

const ContentSection = (props) => {
  const {
    sectionTitle,
    bodyContent,
  } = props;
  const [sectionExpanded, setSectionExpanded] = useState(false);
  return (
    <div className={`collapsible-section${sectionExpanded ? ' expanded' : ''}`}>
      <div className="top-container">
        <button
          type="button"
          className="toggle-button"
          onClick={event => setSectionExpanded(!sectionExpanded)}
          aria-label={`See content listed under "${sectionTitle.text}"`}
        >
          <h2 className="section-title">{sectionTitle.text}</h2>
          <div className={`icon ${sectionExpanded ? 'toggled' : ''}`} />
        </button>
      </div>
      <div className="bottom-container">
        <Collapse isOpen={sectionExpanded}>
          <HtmlContent content={bodyContent.html} />
        </Collapse>
      </div>
    </div>
  );
};

const ContentSectionList = ({ sections }) => (
  <div className="content-sections">
    {sections.map((section, index) => (
      <ContentSection
        key={index}
        {...section}
      />
    ))}
  </div>
);

const CollapsibleContentBlock = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
    items: sections,
  } = data;
  const {
    theme,
    headline,
    subtitle,
  } = primary;
  const themeClass = convertColorToTheme(theme.toLowerCase());
  return (
    <section className={`collapsible-content-block theme-${themeClass}`}>
      <Wrapper isBound>
        <div className="intro-text">
          <p className="subtitle">
            {subtitle.text}
          </p>
          <h2 className="title">
            {headline.text}
          </h2>
        </div>
        <ContentSectionList sections={sections} />
      </Wrapper>
    </section>
  );
};

export default CollapsibleContentBlock;
