import React from 'react';
import get from 'lodash/get';
import { Link } from 'gatsby';
import { Wrapper, Image } from '../common';
import { convertColorToTheme } from '../../utils/helpers';
import './PageHero.scss';

const PageHero = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
  } = data;
  const {
    headline,
    subtitle,
    description,
    theme,
    illustration,
    illustrationStyle,
    illustrator,
  } = primary;
  const artistName = get(illustrator, 'document.data.name.text');
  return (
    <section className={`page-hero theme-${convertColorToTheme(theme.toLowerCase())}`}>
      <Wrapper>
        <div className="cols">
          <div className="col col-left">
            <div className={`illustration ${illustrationStyle.toLowerCase()}`}>
              <div className="image-container">
                <Image image={illustration} />
                {artistName && (
                  <div className="artist-shout-out">
                    <div className="signature-container">
                      <span>Art by</span>
                      <Link to="/artists/">
                        {artistName}
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col col-right">
            <div className="text-container">
              <span className="subtitle">{subtitle.text}</span>
              <h1 className="headline">{headline.text}</h1>
              <p className="description">{description.text}</p>
            </div>
          </div>
        </div>
      </Wrapper>
    </section>
  );
};

export default PageHero;
