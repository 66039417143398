const Entities = require('html-entities').AllHtmlEntities;

export const isClient = typeof window !== 'undefined';
export const decodeEntities = content => Entities.decode(content);

export const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  for (let i = arr1.length; i--;) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};

// export const getSeededRandom = seedStr => seedRandom(seedStr)();
export const getNumberedAffix = index => `${(index < 9) ? `0${index + 1}` : index + 1}`;
export const toKebabCase = str => str.replace(/\s+/g, '-').toLowerCase();
export const convertColorToTheme = (colorStr) => {
  if (colorStr === 'purple') return 'primary';
  if (colorStr === 'green') return 'secondary';
  if (colorStr === 'yellow') return 'tertiary';
  if (colorStr === 'pink') return 'quaternary';
  if (colorStr === 'white') return 'white';
  return null;
};
export const getSectionId = (index, key) => `${getNumberedAffix(index)}-${toKebabCase(key)}`;

export const getPrice = price => (price ? `$${parseFloat(price).toFixed(2)}` : '$0.00');

export const getPriceRange = (variants) => {
  const variantPrices = variants.map(variant => variant.price);
  const min = Math.min(...variantPrices);
  const max = Math.max(...variantPrices);
  return min === max ? `$${min.toFixed(2)}` : `$${min.toFixed(2)} - $${max.toFixed(2)}`;
};

export const copyToClipboard = (stringToCopy) => {
  const el = document.createElement(`textarea`);
  el.value = stringToCopy;
  el.setAttribute(`readonly`, ``);
  el.style.position = `absolute`;
  el.style.left = `-9999px`;
  document.body.appendChild(el);
  el.select();
  document.execCommand(`copy`);
  document.body.removeChild(el);
};
export const scrollToPageElement = (event, elementId) => {
  if (event) event.preventDefault();
  const targetEl = document.getElementById(elementId);
  if (targetEl) targetEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
};
