import React, { Component } from 'react';
import { Link } from 'gatsby';
import {
  Wrapper,
  HtmlContent,
  Flag,
  SocialLink,
  SubscribeBlock,
  MixedLink,
} from '../common';
import {
  FLAG_TYPE_LGBT,
  FLAG_TYPE_TRANS,
  FLAG_TYPE_ABORIGINAL,
  FLAG_TYPE_TORRES_STRAIT,
} from '../common/Flag';
import './Footer.scss';


const TopRow = (props) => {
  const {
    businessName,
    address,
    addressGoogleMapsLink,
    openingTime,
    openingDay,
    closingTime,
    closingDay,
    footerLinks,
    socialLinks,
    australianCharityText,
  } = props;
  return (
    <div className="row top-row">
      <Wrapper isBound>
        <div className="columns">
          <div className="col col-left">
            <div className="office-details">
              <p className="business-name">
                <strong>
                  {businessName.text}
                </strong>
              </p>
              <a
                href={addressGoogleMapsLink.url}
                target="_blank"
                rel="noopener noreferrer"
                className="address"
              >
                <HtmlContent content={address.html} />
              </a>
              <div className="office-hours">
                <p className="open-days">
                  <strong>{openingDay.text}</strong>
                  <span> - </span>
                  <strong>{closingDay.text}</strong>
                </p>
                <p className="open-hours">
                  {`${openingTime.text} - ${closingTime.text}`}
                </p>
              </div>
            </div>
            <div className="footer-links">
              { footerLinks && footerLinks.length > 0 && (
                <ul className="links">
                  {footerLinks.map((link, index) => (
                    <li key={index}>
                      <MixedLink {...link} />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="col col-right">
            <div className="socials">
              { socialLinks && socialLinks.length > 0 && (
                <ul className="social-links">
                  {socialLinks.map(item => (
                    <li key={item.type}>
                      <SocialLink socialLink={item} />
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="charity-stamp">
              <div className="charity-text">
                <HtmlContent content={australianCharityText.html} />
              </div>
              <div className="charity-logo" />
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

const MiddleRow = (props) => {
  const {
    traditionalLandownersText,
    isHome,
  } = props;
  return (
    <div className="row middle-row">
      <Wrapper isBound>
        <div className="columns">
          <div className="col col-left">
            <div className="flag-grid">
              <Flag flagType={FLAG_TYPE_ABORIGINAL} />
              <Flag flagType={FLAG_TYPE_TORRES_STRAIT} />
              <Flag flagType={FLAG_TYPE_LGBT} />
              <Flag flagType={FLAG_TYPE_TRANS} />
            </div>
            <div className="statement">
              <p>
                {traditionalLandownersText.text}
              </p>
            </div>
          </div>
          <div className="col col-right">
            <div className="signatures">
              <div className="signature-art">
                <p className="signature">
                  <Link
                    to="/artists/"
                  >
                    About the Artists
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

class Footer extends Component {
  render() {
    const {
      settings,
      location,
    } = this.props;
    const {
      businessName,
      address,
      addressGoogleMapsLink,
      openingTime,
      openingDay,
      closingTime,
      closingDay,
      footerLinks,
      socialLinks,
      australianCharityText,
      traditionalLandownersText,
    } = settings;
    const { pathname: currentPath } = location;
    const isHome = Boolean(currentPath === '/');
    return (
      <footer className="footer">
        <section className="edm-signup">
          <Wrapper isBound>
            <span className="edm-title">Stay in the loop</span>
            <SubscribeBlock />
          </Wrapper>
        </section>
        <section className="footer-rows">
          <TopRow
            businessName={businessName}
            address={address}
            addressGoogleMapsLink={addressGoogleMapsLink}
            openingTime={openingTime}
            openingDay={openingDay}
            closingTime={closingTime}
            closingDay={closingDay}
            footerLinks={footerLinks}
            socialLinks={socialLinks}
            australianCharityText={australianCharityText}
          />
          <MiddleRow
            traditionalLandownersText={traditionalLandownersText}
            isHome={isHome}
          />
        </section>
      </footer>
    );
  }
}

export default Footer;
