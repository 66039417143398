import React from 'react';
import {
  HtmlContent,
  Buttons,
} from '../common';
import './PostRichText.scss';

const PostRichText = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
    items: buttons,
  } = data;
  const {
    textBody,
  } = primary;
  return (
    <section className="post-rich-text">
      <HtmlContent content={textBody.html} />
      {buttons && buttons.length > 0 && (
        <Buttons
          buttons={buttons}
        />
      )}
    </section>
  );
};

export default PostRichText;
