import React from 'react';
import {
  Image,
} from '../common';
import './PostImage.scss';

const PostImage = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
  } = data;
  const {
    image,
    imageCaption,
    imageStyle = 'cover',
  } = primary;
  return (
    <section className={`post-image ${imageStyle?.toLowerCase()}`}>
      <div className="image-container">
        <Image image={image} className={imageStyle?.toLowerCase()} />
      </div>
      <span className="caption">{imageCaption.text}</span>
    </section>
  );
};

export default PostImage;
