import React from 'react';
import './Wrapper.scss';

const Wrapper = (props) => {
  const {
    isBound,
    children,
  } = props;
  return (
    <div className={`wrapper${isBound ? ' is-bound' : ''}`}>
      {children}
    </div>
  );
};

export default Wrapper;
