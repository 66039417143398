import React from 'react';
import './QuickExitButton.scss';

export const QuickExitButton = ({ quickExitHandler, navOpen }) => (
  <div className={`quick-exit-button${navOpen ? ' nav-open' : ''}`}>
    <button
      type="button"
      onClick={event => quickExitHandler(event)}
      aria-label="Leave the website immediately"
    >
      <span className="label">Quick Exit</span>
    </button>
  </div>
);


export default QuickExitButton;
