import React from 'react';
import get from 'lodash/get';
import NavItem from './NavItem';
import './Navigation.scss';

export const Navigation = (props) => {
  const {
    navigation,
    scrolled,
    navOpen,
    closeNavHander,
    activeParent,
    showChildrenHandler,
  } = props;
  if (!navigation || navigation.length < 1) return null;
  return (
    <nav
      className={`navigation${scrolled ? ' scrolled' : ''}${navOpen ? ' nav-open' : ''}`}
      role="navigation"
    >
      <div className="nav-items" data-body-scroll-lock-ignore>
        {navigation.map((navItem) => {
          const {
            id,
            primary,
            items,
          } = navItem;
          const {
            label,
            link,
          } = primary;

          const navChildren = get(items, '[0].subNav.document.data.body', []);
          const hasChildren = Boolean(navChildren && navChildren.length > 0);

          return (
            <NavItem
              key={id}
              id={id}
              link={link}
              label={label}
              navChildren={hasChildren ? navChildren : null}
              activeParent={activeParent}
              showChildrenHandler={showChildrenHandler}
              closeNavHander={closeNavHander}
            />
          );
        })}
      </div>
    </nav>
  );
};


export default Navigation;
