import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import smoothscroll from 'smoothscroll-polyfill';
import get from 'lodash/get';
import {
  Header,
  Overlay,
  Footer,
  QuickExitButton,
  DialogueButton,
} from '.';
import { SEO } from '..';
import 'typeface-work-sans';
import '../../sass/global/styles.scss';

const isClient = typeof window !== 'undefined';
const viewportUnitsBuggyfill = isClient ? require('viewport-units-buggyfill') : null;

class PureLayout extends Component {
  state = {
    hasMounted: false,
    navOpen: false,
    dialogueOpen: false,
    activeNavParent: null,
  };

  componentDidMount() {
    if (isClient) {
      viewportUnitsBuggyfill.init();
      smoothscroll.polyfill();
      this.setViewportHeight();
      document.addEventListener('keydown', this.handleEscKey, false);
      window.addEventListener('resize', this.setViewportHeight);
    }
    this.setState({
      hasMounted: true,
    });
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener('keydown', this.handleEscKey, false);
      window.removeEventListener('resize', this.setViewportHeight);
    }
  }

  setViewportHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  handleEscKey = (event) => {
    if (event.keyCode === 27) {
      this.redirectToGoogle(event);
    }
  }

  redirectToGoogle = (event) => {
    event.preventDefault();
    if (isClient) {
      setTimeout(() => {
        window.location.href = 'https://www.google.com.au/';
      }, 100);
    }
  }

  toggleNav = (event) => {
    event.preventDefault();
    const { navOpen } = this.state;
    if (event) event.preventDefault();
    if (navOpen) document.body.classList.remove('nav-open');
    if (!navOpen) document.body.classList.add('nav-open');
    this.setState({
      navOpen: !navOpen,
    });
  };

  openNav = (event) => {
    if (event) event.preventDefault();
    document.body.classList.add('nav-open');
    this.setState({
      navOpen: true,
    });
  };

  closeNav = () => {
    document.body.classList.remove('nav-open');
    this.setState({
      navOpen: false,
    });
  };

  setDialogueOpen = (newState) => {
    this.setState({
      dialogueOpen: newState,
    });
  };

  setActiveNavParent = (event, parentId) => {
    if (event) event.preventDefault();
    const { activeNavParent } = this.state;
    if (activeNavParent === parentId) {
      this.setState({
        activeNavParent: null,
      });
    } else {
      this.setState({
        activeNavParent: parentId,
      });
    }
  };

  render() {
    const {
      hasMounted,
      activeNavParent,
      navOpen,
      dialogueOpen,
    } = this.state;
    const {
      children,
      location,
      settings,
      seoData,
      backgroundColor = '',
    } = this.props;
    const { headerNavigation, donateLink } = settings;
    const topLevelNavItems = get(headerNavigation, 'document.data.body', []);
    const {
      metaTitle = null,
      metaDescription = null,
      openGraphImage = null,
    } = seoData;
    const isHome = Boolean(location.pathname === '/');
    const pageClasses = `${isHome ? 'home' : location.pathname.replace(/\//g, '')} ${backgroundColor}`;
    return (
      <>
        <SEO
          title={metaTitle && metaTitle.text}
          desc={metaDescription && metaDescription.text}
          banner={openGraphImage && openGraphImage.url}
          pathname={location.pathname}
        />
        <div id="app" className="app" style={{ opacity: hasMounted ? 1 : 0 }}>
          <a
            className="button styled-button skip-to-content-link"
            href="#main"
          >
            Skip to content
          </a>
          <Header
            donateLink={donateLink}
            navOpen={navOpen}
            dialogueOpen={dialogueOpen}
            location={location}
            navigation={topLevelNavItems}
            toggleNavHandler={event => this.toggleNav(event)}
            closeNavHander={this.closeNav}
            quickExitHandler={event => this.redirectToGoogle(event)}
            activeNavParent={activeNavParent}
            setActiveNavParent={this.setActiveNavParent}
          />
          <Overlay
            show={activeNavParent !== null || dialogueOpen}
            onClick={(e) => {
              this.setActiveNavParent(e, null);
              this.setDialogueOpen(false);
            }}
          />
          <QuickExitButton
            navOpen={navOpen}
            quickExitHandler={event => this.redirectToGoogle(event)}
          />
          <DialogueButton
            navOpen={navOpen || activeNavParent !== null}
            dialogueOpen={dialogueOpen}
            setDialogueOpen={value => this.setDialogueOpen(value)}
          />
          <main id="main" className={pageClasses}>
            {children}
          </main>
          <Footer
            location={location}
            settings={settings}
          />
        </div>
      </>
    );
  }
}

class Layout extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            settings: prismicSettings {
              data {
                address {
                  html
                }
                addressGoogleMapsLink: address_google_maps_link {
                  link_type
                  target
                  url
                }
                australianCharityText: australian_charity_text {
                  html
                }
                donateLink: donate_link {
                  slug
                  url
                  linkType: link_type
                  target
                }
                traditionalLandownersText: traditional_landowners_text {
                  text
                }
                socialLinks: social_links {
                  link {
                    url
                    target
                    linkType: link_type
                  }
                  type
                }
                openingTime: opening_time {
                  text
                }
                openingDay: opening_day {
                  text
                }
                footerLinks: footer_links {
                  link {
                    slug
                    url
                    linkType: link_type
                    target
                  }
                  linkLabel: link_label {
                    text
                  }
                }
                closingTime: closing_time {
                  text
                }
                closingDay: closing_day {
                  text
                }
                businessName: business_name {
                  text
                }
                headerNavigation: header_navigation {
                  document {
                    ... on PrismicNavigationMenu {
                      id
                      data {
                        body {
                          ... on PrismicNavigationMenuBodyNavItem {
                            id
                            primary {
                              label {
                                text
                              }
                              link {
                                slug
                                url
                              }
                            }
                            items {
                              subNav: sub_nav {
                                document {
                                  ... on PrismicSubNavigation {
                                    id
                                    data {
                                      body {
                                        ... on PrismicSubNavigationBodyNavItem {
                                          id
                                          primary {
                                            link {
                                              url
                                              slug
                                            }
                                            label {
                                              text
                                            }
                                            description {
                                              text
                                            }
                                          }
                                          items {
                                            subNavLink: sub_nav_link {
                                              url
                                              slug
                                            }
                                            subNavLinkLabel: sub_nav_link_label {
                                              text
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                body {
                  ... on PrismicSettingsBodyNavItem {
                    primary {
                      label {
                        text
                      }
                      link {
                        linkType: link_type
                        url
                        target
                        slug
                      }
                    }
                    navChildren: items {
                      subNavLink: sub_nav_link {
                        url
                        type
                        target
                        slug
                      }
                      subNavLinkLabel: sub_nav_link_label {
                        text
                      }
                      description {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => <PureLayout {...this.props} settings={data.settings.data} />}
      />
    );
  }
}

export default Layout;
