import React from 'react';
import cn from 'classnames';
import Plyr from '../Plyr/Plyr';
import './VideoBlock.scss';

const VideoBlock = (props) => {
  const {
    className,
    videoUrl,
    videoProvider,
  } = props;
  return (
    <div className={cn('video-block', className || null)}>
      <Plyr
        videoProvider={videoProvider}
        videoUrl={videoUrl}
      />
    </div>
  );
};

export default VideoBlock;
