import React, { Component } from 'react';
import cn from 'classnames';
import {
  HomeHero,
  IllustratedActions,
  ImageTextBlock,
  TextAndLinksBlock,
  LatestPostsBlock,
  ContactFormBlock,
  PageHero,
  CallToActions,
  CallToActionTiles,
  ResourceTiles,
  HotlineAccordion,
  GridBlock,
  BreadcrumbsSlice,
  CollapsibleContentBlock,
  VideoTextBlock,
  BigImageTextBlock,
  TextHero,
  TeamGallery,
  DocumentCarousel,
  PostRichText,
  PostButtons,
  PostFeaturedText,
  PostImage,
  PostVideo,
  ImageBlock,
  VideoBlock,
} from './Slices';

import './SliceZone.scss';

const HOME_HERO_TYPE = 'home_hero';
const ILLUSTRATED_ACTIONS_TYPE = 'illustrated_actions';
const IMAGE_TEXT_BLOCK_TYPE = 'image_text_block';
const TEXT_AND_LINKS_BLOCK_TYPE = 'text_and_links_block';
const LATEST_POSTS_BLOCK_TYPE = 'latest_posts_block';
const CONTACT_FORM_BLOCK_TYPE = 'contact_form_block';
const PAGE_HERO_TYPE = 'page_hero';
const CALL_TO_ACTIONS_TYPE = 'call_to_actions';
const CALL_TO_ACTION_TILES_TYPE = 'call_to_action_tiles';
const RESOURCE_TILES_TYPE = 'resource_tiles';
const HOTLINE_ACCORDION_TYPE = 'hotline_accordion';
const GRID_BLOCK_TYPE = 'grid_block';
const BREADCRUMBS_SLICE_TYPE = 'breadcrumbs';
const COLLAPSIBLE_CONTENT_BLOCK_TYPE = 'collapsible_content_block';
const TEAM_GALLERY_TYPE = 'team_gallery';
const VIDEO_TEXT_BLOCK_TYPE = 'video_text_block';
const BIG_TEXT_IMAGE_BLOCK_TYPE = 'big_image_text_block';
const TEXT_HERO_TYPE = 'text_hero';
const VIDEO_BLOCK_TYPE = 'video_block';
const IMAGE_BLOCK_TYPE = 'image_block';
const DOCUMENT_CAROUSEL_TYPE = 'document_carousel';
const BLOG_POST_RICH_TEXT_TYPE = 'rich_text';
const BLOG_POST_BUTTONS_TYPE = 'buttons';
const BLOG_POST_FEATURED_TEXT_TYPE = 'featured_text';
const BLOG_POST_POST_VIDEO_TYPE = 'post_video';
const BLOG_POST_POST_IMAGE_TYPE = 'post_image';


export default class SliceZone extends Component {
  render() {
    const {
      pageSlug,
      pageTitle,
      allSlices,
      siteMetadata,
      location,
    } = this.props;
    const { pathname: currentPath } = location;
    const isBlog = currentPath.includes('blog');
    const slices = allSlices && allSlices.map((slice, index) => {
      const isFirst = index === 0;
      switch (slice.sliceType) {
        case HOME_HERO_TYPE:
          return (
            <HomeHero
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case ILLUSTRATED_ACTIONS_TYPE:
          return (
            <IllustratedActions
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case BREADCRUMBS_SLICE_TYPE:
          return (
            <BreadcrumbsSlice
              key={slice.id}
              pageSlug={pageSlug}
              pageTitle={pageTitle}
              data={slice}
              isFirst={isFirst}
            />
          );
        case IMAGE_TEXT_BLOCK_TYPE:
          return (
            <ImageTextBlock
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case TEXT_AND_LINKS_BLOCK_TYPE:
          return (
            <TextAndLinksBlock
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case LATEST_POSTS_BLOCK_TYPE:
          return (
            <LatestPostsBlock
              key={slice.id}
              data={slice}
              siteMetadata={siteMetadata}
              isFirst={isFirst}
            />
          );
        case CONTACT_FORM_BLOCK_TYPE:
          return (
            <ContactFormBlock
              key={slice.id}
              data={slice}
              location={location}
              isFirst={isFirst}
            />
          );
        case PAGE_HERO_TYPE:
          return (
            <PageHero
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case CALL_TO_ACTIONS_TYPE:
          return (
            <CallToActions
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case CALL_TO_ACTION_TILES_TYPE:
          return (
            <CallToActionTiles
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case RESOURCE_TILES_TYPE:
          return (
            <ResourceTiles
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case HOTLINE_ACCORDION_TYPE:
          return (
            <HotlineAccordion
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case GRID_BLOCK_TYPE:
          return (
            <GridBlock
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case COLLAPSIBLE_CONTENT_BLOCK_TYPE:
          return (
            <CollapsibleContentBlock
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case BIG_TEXT_IMAGE_BLOCK_TYPE:
          return (
            <BigImageTextBlock
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case TEXT_HERO_TYPE:
          return (
            <TextHero
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case VIDEO_BLOCK_TYPE:
          return (
            <VideoBlock
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case IMAGE_BLOCK_TYPE:
          return (
            <ImageBlock
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case VIDEO_TEXT_BLOCK_TYPE:
          return (
            <VideoTextBlock
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case TEAM_GALLERY_TYPE:
          return (
            <TeamGallery
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case DOCUMENT_CAROUSEL_TYPE:
          return (
            <DocumentCarousel
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case BLOG_POST_RICH_TEXT_TYPE:
          return (
            <PostRichText
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case BLOG_POST_BUTTONS_TYPE:
          return (
            <PostButtons
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case BLOG_POST_FEATURED_TEXT_TYPE:
          return (
            <PostFeaturedText
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case BLOG_POST_POST_IMAGE_TYPE:
          return (
            <PostImage
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        case BLOG_POST_POST_VIDEO_TYPE:
          return (
            <PostVideo
              key={slice.id}
              data={slice}
              isFirst={isFirst}
            />
          );
        default:
          return null;
      }
    });
    return (
      <div className={cn('slice-zone', { isBlog })}>
        {slices}
      </div>
    );
  }
}
