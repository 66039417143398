import React from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import {
  Wrapper,
  Buttons,
  HtmlContent,
} from '../common';
import './TextHero.scss';

const TextHero = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
    items: buttons,
  } = data;
  const {
    headline,
    description,
    theme,
  } = primary;
  const hasButtons = get(buttons, '0.buttonLabel.text');
  return (
    <section className={cn('text-hero', theme && theme.toLowerCase())}>
      <Wrapper isBound>
        <div className="container">
          {headline && headline?.html && (
            <HtmlContent
              className={cn('headline', theme && theme.toLowerCase())}
              content={headline.html}
            />
          )}
          {description && description?.html && (
            <HtmlContent className="description" content={description.html} />
          )}
          {hasButtons && (
            <Buttons
              buttons={buttons}
            />
          )}
        </div>
      </Wrapper>
    </section>
  );
};

export default TextHero;
