import React from 'react';
import './SliderButtons.scss';

const SliderButtons = (props) => {
  const {
    showPrevButton,
    showNextButton,
    goToPrevSlideHandler,
    goToNextSlideHandler,
  } = props;
  return (
    <div className="slider-buttons">
      <button
        className={`slider-button slider-button-prev${showPrevButton ? ' hidden' : ''}`}
        type="button"
        aria-label="Go to previous slide"
        onClick={event => goToPrevSlideHandler(event)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 26">
          <path
            fill="none"
            fillRule="nonzero"
            stroke="#2D235F"
            strokeWidth="1.5"
            d="M37.4047 16.3757H12.6002l6.5337 7.4335c.4303.4695.0782 1.252-.5477 1.252h-6.964c-.1957 0-.4304-.0783-.5478-.2348L1.176 13.5196c-.2348-.2738-.2348-.7042 0-.978l9.8983-11.3069c.1565-.1565.352-.2347.5477-.2347h6.964c.626 0 .9781.7434.5478 1.252l-6.5337 7.4335h24.8045c.3912 0 .7434.3521.7434.7433v5.2426c0 .3521-.3522.7043-.7434.7043z"
          />
        </svg>
      </button>
      <button
        className={`slider-button slider-button-next${showNextButton ? ' hidden' : ''}`}
        type="button"
        aria-label="Go to next slide"
        onClick={event => goToNextSlideHandler(event)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 27">
          <path
            fill="none"
            fillRule="nonzero"
            stroke="#2D235F"
            strokeWidth="1.5"
            d="M2.311 16.9323h24.8045l-6.5337 7.4335c-.4304.4695-.0783 1.252.5477 1.252h6.964c.1957 0 .4304-.0783.5478-.2348l9.8983-11.3067c.2348-.274.2348-.7043 0-.9781L28.6413 1.7914c-.1565-.1565-.3521-.2348-.5477-.2348h-6.964c-.626 0-.9782.7434-.5478 1.252l6.5337 7.4335H2.311c-.3913 0-.7434.3521-.7434.7434v5.2426c0 .352.3521.7042.7434.7042z"
          />
        </svg>
      </button>
    </div>
  );
};

export default SliderButtons;
