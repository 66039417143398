import { useCallback, useEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Emitter from 'tiny-emitter';
import gsap from 'gsap';

const emitter = new Emitter();

export function getScrollbarGap () {
  return window.innerWidth - document.documentElement.clientWidth;
}

export function useReserveScrollbarGap (ref) {
  useBodyScrollLockListener(useCallback((enabled) => {
    gsap.set(ref.current, { paddingRight: enabled ? 0 : getScrollbarGap() });
  }, []));
}

export function useBodyScrollLockListener (fn) {
  useEffect(() => {
    const onDisabled = () => { fn(false); };
    const onEnabled = () => { fn(true); };
    emitter.on('body-scroll-disabled', onDisabled);
    emitter.on('body-scroll-enabled', onEnabled);
    return () => {
      emitter.off('body-scroll-disabled', onDisabled);
      emitter.off('body-scroll-enabled', onEnabled);
    };
  }, [fn]);
}

export function lock (el, options) {
  emitter.emit('body-scroll-disabled');
  disableBodyScroll(el, options);
}

export function unlock (el) {
  emitter.emit('body-scroll-enabled');
  enableBodyScroll(el);
}

export function unlockAll () {
  emitter.emit('body-scroll-enabled');
  clearAllBodyScrollLocks();
}
export default function useBodyScrollLock (locked, options = {}) {
  const state = useRef({ el: null, locked });

  return useCallback((el) => {
    if (el && locked) {
      lock(el, { reserveScrollBarGap: false, ...options });
    }

    const { el: prevEl, locked: prevLocked } = state.current;
    if (prevEl && prevLocked) {
      unlock(prevEl);
    }

    state.current.el = el;
    state.current.locked = locked;
  }, [locked]);
}
