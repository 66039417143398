import React from 'react';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';
import FocusTrap from 'focus-trap-react';
import { DialogueWindow } from '../../dialogue';
import DialogueTooltip from './DialogueTooltip';
import { ReactComponent as CloseIcon } from '../../icons/close-icon.svg';
import { ReactComponent as ChatIcon } from '../../icons/here-for-you-chat.svg';
import useBodyScrollLock from '../../../hooks/useBodyScrollLock';
import './DialogueButton.scss';

const bodyScrollLockOptions = {
  allowTouchMove: (el) => {
    while (el && el !== document.body) {
      if (el.getAttribute('data-body-scroll-lock-ignore') !== null) {
        return true;
      }
      el = el.parentElement; // eslint-disable-line
    }
  },
};

const DialogueButton = ({
  navOpen,
  dialogueOpen,
  setDialogueOpen,
}) => {
  const dialogueToggleHandler = () => {
    if (dialogueOpen) document.body.classList.remove('dialogue-window-open');
    if (!dialogueOpen) document.body.classList.add('dialogue-window-open');
    setDialogueOpen(!dialogueOpen);
  };

  const closeDialogueHandler = () => {
    document.body.classList.remove('dialogue-window-open');
    setDialogueOpen(false);
  };

  const classes = cn(
    'dialogue-button',
    { navOpen },
    { dialogueOpen },
  );

  const bodyScrollRef = useBodyScrollLock(dialogueOpen, bodyScrollLockOptions);

  return (
    <div ref={bodyScrollRef} className={classes}>
      <FocusTrap active={dialogueOpen}>
        <div className="dialogue-container">
          <CSSTransition
            in={dialogueOpen}
            timeout={300}
            classNames="animated-window"
          >
            <DialogueWindow
              dialogueOpen={dialogueOpen}
              closeDialogueHandler={closeDialogueHandler}
            />
          </CSSTransition>
          <button
            id="dialogue-toggle-button"
            type="button"
            onClick={() => dialogueToggleHandler()}
            aria-label={
              dialogueOpen
                ? 'Hide the interactive questionnaire'
                : 'Access an interactive questionnaire about domestic violence'
            }
          >
            {!dialogueOpen ? <ChatIcon /> : <CloseIcon />}
          </button>
          <DialogueTooltip
            dialogueOpen={dialogueOpen}
            dialogueToggleHandler={dialogueToggleHandler}
          />
        </div>
      </FocusTrap>
    </div>
  );
};


export default DialogueButton;
