import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import { Image, Wrapper } from '../common';
import { SliderButtons } from '../Slider';
import { convertColorToTheme, isClient } from '../../utils/helpers';
import './TeamGallery.scss';

const TeamProfile = (props) => {
  const {
    name,
    profilePhoto,
    jobTitle,
  } = props;
  return (
    <div className="team-member">
      <div className="photo">
        <Image image={profilePhoto} />
      </div>
      <div className="text-container">
        <span className="name">{name.text}</span>
        <span className="job-title">{jobTitle.text}</span>
      </div>
    </div>
  );
};

const TeamGallery = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
    items: teamMembers,
  } = data;
  const {
    theme,
    title,
    subtitle,
  } = primary;
  const themeClass = convertColorToTheme(theme.toLowerCase());
  const ref = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: index => setActiveIndex(index),
  };
  const goToNextSlide = (event) => {
    event.preventDefault();
    ref.current.slickNext();
  };
  const goToPrevSlide = (event) => {
    event.preventDefault();
    ref.current.slickPrev();
  };
  let slidesToShow = 3.5;
  const viewportWidth = isClient ? window.innerWidth : 1600;
  if (viewportWidth <= 1440) slidesToShow = 2.5;
  if (viewportWidth <= 800) slidesToShow = 1.5;
  const showNextButton = Boolean(activeIndex >= ((teamMembers.length) - Math.floor(slidesToShow)));
  const showPrevButton = Boolean(activeIndex === 0);
  return (
    <section className={`team-gallery theme-${themeClass}`}>
      <Wrapper>
        <div className="intro-text">
          <p className="subtitle">{subtitle.text}</p>
          <h2 className="headline">{title.text}</h2>
        </div>
      </Wrapper>
      <div className="slider-container">
        <SliderButtons
          showNextButton={showNextButton}
          showPrevButton={showPrevButton}
          goToNextSlideHandler={goToNextSlide}
          goToPrevSlideHandler={goToPrevSlide}
        />
        <div className="team-members">
          <Slider ref={ref} {...settings}>
            {teamMembers && teamMembers.map((member, index) => (
              <TeamProfile
                key={index}
                {...member}
              />
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default TeamGallery;
