import React from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';

const MixedLink = (props) => {
  const {
    className,
    link,
    linkLabel,
    children,
  } = props;
  if (!link) return null;
  const { slug, url } = link;
  let to = slug ? `/${slug === 'home' ? '' : `${slug}`}` : null;
  const isAbsolute = new RegExp('^(//|[a-z]+:)', 'i');
  if (!isAbsolute.test(url)) {
    to = url;
  }
  if (to) {
    return (
      <Link
        to={to}
        className={cn('link', className || null)}
        aria-label={`Navigate to ${linkLabel.text}`}
      >
        {children || (
          <span>{linkLabel.text}</span>
        )}
      </Link>
    );
  } else if (url) { // eslint-disable-line
    return (
      <a
        href={link.url}
        className={cn('link', className || null)}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`Navigate to ${linkLabel.text}`}
      >
        {children || (
          <span>{linkLabel.text}</span>
        )}
      </a>
    );
  }
  return (
    children || <span>{linkLabel.text}</span>
  );
};

export default MixedLink;
