import React from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import {
  Buttons,
  Image,
  HtmlContent,
  Wrapper,
} from '../common';
import './CallToActionTiles.scss';

const Tile = ({
  image,
  title,
  description,
  buttonLink,
  buttonLabel,
  imageClass,
}) => {
  const buttons = [{
    buttonLink,
    buttonLabel,
  }];
  const hasButtons = get(buttons, '0.buttonLabel.text');
  const titleParts = title && title.text ? title.text.split(' ') : null;
  return (
    <div className="tile">
      <div className={cn('image-container', imageClass)}>
        <Image className="image" image={image} />
      </div>
      {titleParts && (
        <h2 className="title">
          {titleParts.map((part, index) => <span className="title-part" key={`${part}-${index}`}>{part}</span>)}
        </h2>
      )}
      <HtmlContent className="description" content={description.html} />
      {hasButtons && (
        <Buttons
          buttons={buttons}
        />
      )}
    </div>
  );
};

const CallToActionTiles = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
    items: tiles,
  } = data;
  const {
    title,
    itemsPerRow,
  } = primary;
  if (!tiles || tiles.length < 1) return null;
  const imageClass = tiles.length > 3 ? 'image-square' : 'image-rectangle';
  return (
    <section className="call-to-action-tiles">
      <Wrapper isBound>
        {title?.text && <h2 className="section-title">{title?.text}</h2>}
        <div className={cn('tiles', itemsPerRow ? itemsPerRow.toLowerCase() : null)}>
          {tiles.map((tile, index) => (
            <Tile
              key={index}
              imageClass={imageClass}
              {...tile}
            />
          ))}
        </div>
      </Wrapper>
    </section>
  );
};

export default CallToActionTiles;
