import { useCallback } from 'react';

const composeRefs = (...refs) => useCallback((el) => { // eslint-disable-line
  refs.forEach((ref) => {
    if (typeof ref === 'function') {
      ref(el);
    } else if (ref) {
      ref.current = el;
    }
  });
}, refs);

export default composeRefs;
