import React, { Component, useRef, useState } from 'react';
import Slider from 'react-slick';
import { Link, StaticQuery, graphql } from 'gatsby';
import { SliderButtons } from '../Slider';
import { PostListItem } from '.';
import { Wrapper } from '../common';
import { isClient } from '../../utils/helpers';
import './LatestPosts.scss';

const LatestPostsTemplate = (props) => {
  const {
    headline,
    subtitle,
    blogSlug,
    posts,
    hideButton,
    grayscalePhotos,
  } = props;
  const ref = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: index => setActiveIndex(index),
  };
  const goToNextSlide = (event) => {
    event.preventDefault();
    ref.current.slickNext();
  };
  const goToPrevSlide = (event) => {
    event.preventDefault();
    ref.current.slickPrev();
  };
  const postItems = posts && posts.edges.map(post => post.node);
  let slidesToShow = 3.5;
  const viewportWidth = isClient ? window.innerWidth : 1600;
  if (viewportWidth <= 1440) slidesToShow = 2.5;
  if (viewportWidth <= 800) slidesToShow = 1.5;
  const showNextButton = Boolean(activeIndex >= ((postItems.length) - Math.floor(slidesToShow)));
  const showPrevButton = Boolean(activeIndex === 0);
  return (
    <div className="latest-posts">
      <Wrapper>
        <div className="top-text">
          {subtitle && (
            <span className="subtitle">
              {subtitle.text}
            </span>
          )}
          <div className="headline">
            <h2>{headline.text}</h2>
            {!hideButton && (
              <Link
                to={`/${blogSlug}/`}
                className="button styled-button"
              >
                See all
              </Link>
            )}
          </div>
        </div>
      </Wrapper>
      <div className="slider-container">
        <SliderButtons
          showNextButton={showNextButton}
          showPrevButton={showPrevButton}
          goToNextSlideHandler={goToNextSlide}
          goToPrevSlideHandler={goToPrevSlide}
        />
        <div className={`posts${grayscalePhotos ? ' grayscale-photos' : ''}`}>
          <Slider ref={ref} {...settings}>
            {postItems && postItems.map(post => (
              <PostListItem
                key={post.slug}
                blogSlug={blogSlug}
                {...post}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

class LatestPosts extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query LatestPostsQuery {
            posts: allPrismicBlogPost(
              limit: 5,
              sort: { fields: [data___date], order: DESC}
            ) {
              edges {
                node {
                  uid,
                  data {
                    postTitle: post_title {
                      html
                      text
                    }
                    featuredImage: featured_image {
                      alt
                      fluid(maxWidth: 1000) {
                        ...GatsbyPrismicImageFluid_withWebp
                      }
                    }
                    featuredImageStyle: feature_image_style
                    author {
                      document {
                        ... on PrismicBlogAuthor {
                          id
                          data {
                            authorName: author_name {
                              text
                            }
                          }
                        }
                      }
                    }
                    date(formatString: "YYYY-MM-DD")
                  }
                }
              }
            }
          }
        `}
        render={data => <LatestPostsTemplate {...this.props} posts={data.posts} />}
      />
    );
  }
}


export default LatestPosts;
