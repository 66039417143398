import React from 'react';
import { Link } from 'gatsby';
import { Wrapper, HtmlContent } from '../common';
import { convertColorToTheme } from '../../utils/helpers';
import './CallToActions.scss';

const CallToActions = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
    items: actions,
  } = data;
  const {
    description,
  } = primary;
  if (!actions) return null;
  return (
    <section className="call-to-actions">
      <Wrapper isBound>
        <HtmlContent className="description" content={description.html} />
        <div className="actions">
          {actions.map((action) => {
            const {
              theme,
              actionLabel,
              actionLink,
            } = action;
            const {
              slug,
            } = actionLink;
            const actionClasses = `action theme-${convertColorToTheme(theme.toLowerCase())}`;
            return (
              <Link
                className={actionClasses}
                key={slug}
                to={`/${slug}/`}
              >
                <div className="action-container">
                  <div className="text-container">
                    <div className="label">
                      {actionLabel.text}
                    </div>
                    <svg className="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 22">
                      <path
                        fill="none"
                        fillRule="nonzero"
                        stroke="#2D235F"
                        strokeWidth="1.5"
                        d="M2.1789 13.7805h20.6178l-5.4308 6.1788c-.3578.3903-.065 1.0407.4552 1.0407h5.7887c.1626 0 .3577-.065.4552-.1951l8.2277-9.3984c.1951-.2276.1951-.5854 0-.813L24.065 1.1951c-.13-.13-.2926-.1951-.4552-.1951H17.821c-.5203 0-.813.6179-.4552 1.0407l5.4308 6.1788H2.179c-.3252 0-.618.2927-.618.6179v4.3577c0 .2927.2928.5854.618.5854z"
                      />
                    </svg>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </Wrapper>
    </section>
  );
};

export default CallToActions;
