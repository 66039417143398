import {
  DIALOGUE_NODES,
  WAGEC_MESSAGE,
  USER_MESSAGE,
} from './nodes';

export const TYPING_DELAY = 600;
export const ANIMATION_DELAY = 1500;

export {
  DIALOGUE_NODES,
  WAGEC_MESSAGE,
  USER_MESSAGE,
};
